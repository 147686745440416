export enum CoverModel {
  uFlexCover = 'uFlexCover',
  xCover = 'xCover',
  cFlexCover = 'cFlexCover',
}

export enum ProthesisType {
  thigh = 'thigh',
  lowerLeg = 'lowerLeg',
}

export enum BodySide {
  right = 'Right',
  left = 'Left',
}

export enum ZipType {
  yes = 'yes',
  no = 'no'
}

export enum BalconyType {
  straight = 'straight',
  lLeft = 'lLeft',
  lRight = 'lRight',
  uForm = 'uForm',
  special = 'special'
}

export enum MountingType {
  bottom = 'bottom',
  front = 'front'
}

export enum PaneelType {
  landscape = 'landscape',
  portrait = 'portrait'
}

export enum FenceType {
  straight = 'straight',
  lLeft = 'lLeft',
  lRight = 'lRight',
  uForm = 'uForm',
  special = 'special'
}


import { Options, Vue } from 'vue-class-component';
import { RendererPublicInterface } from 'troisjs';
import { Prop, Watch } from 'vue-property-decorator';
import * as THREECamera from '@/utils/three/camera';
import * as THREE from 'three';
import { ModelSrcType, ObjModel, StlModel } from '@/utils/three/importExport';
import { MeshImportData } from '@/types/ui/MeshTreeData';
import { FileType3D } from '@/types/enum/upload';
import { v4 as uuidv4 } from 'uuid';
import {
  addWebGlContext,
  deleteWebGlContext,
  waitFor,
} from '@/utils/three/webGlContext';

@Options({
  components: {
    ObjModel,
    StlModel,
  },
  emits: ['selectItem', 'removeItem'],
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class PreviewRenderer extends Vue {
  @Prop() meshes!: MeshImportData[];
  @Prop({ default: false }) isSelected!: boolean;
  @Prop({ default: '148' }) size!: string;
  @Prop({ default: 1.2 }) offset!: number;
  @Prop({ default: true }) canSelect!: boolean;
  @Prop({ default: false }) canRemove!: boolean;
  uuid = uuidv4();
  isActive = false;

  //renderer
  troisRenderer!: RendererPublicInterface;

  FileType = FileType3D;
  ModelSrcType = ModelSrcType;

  mounted(): void {
    waitFor(() => addWebGlContext(this.uuid)).then(
      () => (this.isActive = true)
    );
  }

  unmounted(): void {
    if (this.troisRenderer) {
      this.troisRenderer.renderer.dispose();
      this.troisRenderer.renderer.forceContextLoss();
    }
    deleteWebGlContext(this.uuid);
  }

  setTroisRenderer(): void {
    if (this.$refs.troisRenderer) {
      this.troisRenderer = this.$refs.troisRenderer as RendererPublicInterface;
    }
  }

  @Watch('meshes', { immediate: true })
  onMeshesChanged(): void {
    waitFor(() => this.isActive).then(() => {
      setTimeout(() => {
        this.setTroisRenderer();
        if (this.troisRenderer && this.troisRenderer.scene) {
          THREECamera.fitCameraToSelection(
            this.troisRenderer.camera as THREE.PerspectiveCamera,
            this.getSceneObjects(),
            this.offset
          );
        }
      }, 500);
    });
  }

  getMeshesOfType(filetype: FileType3D): MeshImportData[] {
    return this.meshes.filter(
      (item) =>
        item.filename && item.filetype === filetype && (item.url || item.base64)
    );
  }

  get isEmpty(): boolean {
    return (
      this.meshes.filter((item) => item.filename && (item.url || item.base64))
        .length === 0
    );
  }

  selectItem(): void {
    if (this.canSelect) this.$emit('selectItem', this.meshes);
  }

  getSceneObjects(): THREE.Object3D[] {
    if (this.troisRenderer && this.troisRenderer.scene) {
      return this.troisRenderer.scene.children;
    }
    return [];
  }

  removeItem(): void {
    this.meshes;
    this.$emit('removeItem');
  }
}

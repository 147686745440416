
import { Options, Vue } from 'vue-class-component';
import { UploadStatus } from 'element-plus/es/components/upload/src/upload';
import { FileCategory, isValidFileType } from '@/types/enum/upload';
import { ElMessage } from 'element-plus';
import { Prop, Watch } from 'vue-property-decorator';
import { ProjectionCategory } from '@/types/enum/editor';
import type Node from 'element-plus/es/components/tree/src/model/node';
import { ElTree } from 'element-plus/es/components/';
import { Rectangle, Vugel } from 'vugel';
import { fileContentToBase64 } from '@/utils/file';
import { HistoryList, HistoryOperationType } from '@/types/ui/HistoryList';
import * as THREE from 'three';
import { SelectionList } from '@/types/ui/SelectionList';

export interface UploadData {
  name: string;
  url: string;
  uid: number;
  status?: UploadStatus;
}

export interface ProjectionSidebarData {
  projectionCategory: ProjectionCategory;
  aspectRatio: number;
  scaleFactor: number;
  offset: { x: number; y: number };
  embossingFile: string | null;
  color: string | null;
  embossingDefinition: UploadData[];
  colorDefinition: { [name: string]: string[] };
}

export interface EmbossingData {
  id: string | null;
  projection: ProjectionSidebarData[];
  activeCategory: ProjectionCategory;
}

interface ProjectionTreeData {
  id: ProjectionCategory;
  label: string;
  isEnabled: boolean;
  isSelected: boolean;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  children: ProjectionTreeData[];
}

@Options({
  components: { Vugel },
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class EmbossingSidebar extends Vue {
  @Prop({
    default: {
      id: null,
      activeCategory: ProjectionCategory.custom,
      projection: [
        {
          projectionCategory: ProjectionCategory.custom,
          aspectRatio: 1,
          scaleFactor: 1,
          embossingFile: null,
          color: '#ffffff',
          embossingDefinition: [],
          colorDefinition: {},
        },
      ],
    },
  })
  modelValue!: EmbossingData;
  @Prop() readonly selectObject!: (
    target: THREE.Object3D | null,
    setSelectionFlag: boolean
  ) => Promise<void>;
  @Prop() selectionList!: SelectionList;
  @Prop() historyList!: HistoryList;

  embossingList: UploadData[] = [];
  selectedEmbossing = -1;
  selectedEmbossingFile = '';
  embossingScale = 1;
  colors: { [name: string]: string[] } = {};
  selectedColor: string | null = null;
  addColor = '';
  defaultCategory = 'default';
  addCategory = this.defaultCategory;
  currentId: string | null = null;
  addType: ProjectionCategory = ProjectionCategory.custom;
  projectionList: ProjectionTreeData[] = [];
  aspectRatio = 1;

  embossingOffset = { x: 0, y: 0 };
  embossingBorderThickness = 10;

  ProjectionCategory = ProjectionCategory;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  readonly customNodeClass = (data: ProjectionTreeData) => {
    if (data.isSelected) {
      return 'selected';
    }
    return null;
  };

  mounted(): void {
    this.projectionList = Object.keys(ProjectionCategory).map((item) => {
      const pType = item as ProjectionCategory;
      return {
        id: pType,
        label: (this as any).$t(`enum.projection-type.${item}`),
        isEnabled: pType === ProjectionCategory.custom,
        isSelected: pType === ProjectionCategory.custom,
        children: [],
      };
    });
    this.reloadTreeSelection();
  }

  private reloadTreeSelection(): void {
    for (const projectionItem of this.projectionList) {
      if (this.modelValue.projection.length > 0) {
        const modelItem = this.modelValue.projection.find(
          (item) => item.projectionCategory === projectionItem.id
        );
        projectionItem.isEnabled = !!modelItem;
        projectionItem.isSelected =
          this.modelValue.activeCategory === projectionItem.id;
      } else {
        projectionItem.isEnabled =
          ProjectionCategory.custom === projectionItem.id;
        projectionItem.isSelected =
          ProjectionCategory.custom === projectionItem.id;
      }
    }
  }

  handleCheckChange(data: ProjectionTreeData, checked: boolean): void {
    data.isEnabled = checked;
    const index = this.modelValue.projection.findIndex(
      (item) => item.projectionCategory === data.id
    );
    if (checked) {
      if (index === -1) {
        this.modelValue.projection.push({
          projectionCategory: data.id,
          aspectRatio: 1,
          scaleFactor: 1,
          offset: { x: 0, y: 0 },
          embossingFile: null,
          color: null,
          embossingDefinition: [],
          colorDefinition: {},
        });
      }
    } else {
      this.modelValue.projection.splice(index, 1);
    }
  }

  handleNodeClick(data: ProjectionTreeData, node: Node): void {
    this.projectionList.forEach((item) => (item.isSelected = false));
    node.checked = true;
    this.handleCheckChange(data, true);
    data.isSelected = true;
    this.modelValue.activeCategory = data.id;
  }

  getActiveProjection(): ProjectionSidebarData | null {
    const projectionIndex = this.modelValue.projection.findIndex(
      (item) => item.projectionCategory === this.modelValue.activeCategory
    );
    if (projectionIndex > -1)
      return this.modelValue.projection[projectionIndex];
    if (this.modelValue.projection.length > 0)
      return this.modelValue.projection[0];
    return null;
  }

  @Watch('modelValue', { immediate: true, deep: true })
  onModelValueChanged(): void {
    //todo: fix performance - too many calls (deep: true)
    this.syncData();
  }

  syncData(): void {
    //this.reloadTreeSelection();
    const projection = this.getActiveProjection();
    if (projection) {
      this.aspectRatio = projection.aspectRatio;
      this.embossingList = projection.embossingDefinition;
      this.colors = projection.colorDefinition;
      const file = this.embossingList.find(
        (item) => item.url === projection.embossingFile
      );
      if (file) this.selectedEmbossing = file.uid;
      this.selectedColor = projection.color;
      this.embossingScale = projection.scaleFactor;
      this.restoreOffset();
      if (this.modelValue.id !== this.currentId) {
        const categories = Object.keys(projection.colorDefinition);
        if (categories.length > 0) this.addCategory = categories[0];
        else this.addCategory = this.defaultCategory;
        if (projection.color) {
          for (const key of categories) {
            if (projection.colorDefinition[key].includes(projection.color)) {
              this.addCategory = key;
              break;
            }
          }
          this.addColor = projection.color;
        }
        this.addNextColorChanged = false;
      }
      setTimeout(() => {
        this.checkEmbossingOffset();
        this.storeOffset();
      }, 100);
    }
    this.currentId = this.modelValue.id;

    const projectionTree = this.$refs.projectionTree as typeof ElTree;
    if (projectionTree) {
      projectionTree.setCheckedKeys(
        this.modelValue.projection.map((item) => item.projectionCategory)
      );
    }
    setTimeout(() => {
      this.bufferSidebarData();
    }, 500);
  }

  addNextColorChanged = true;
  @Watch('addColor', { immediate: true })
  onAddColorChanged(): void {
    if (
      this.addColor.length > 0 &&
      this.addCategory.length > 0 &&
      this.addNextColorChanged
    ) {
      if (!this.colors[this.addCategory]) this.colors[this.addCategory] = [];
      this.colors[this.addCategory].push(this.addColor);
      const projection = this.getActiveProjection();
      if (projection) projection.colorDefinition = this.colors;
      this.handleSelectColor(this.addColor);
    }
    this.addNextColorChanged = true;
  }

  @Watch('embossingScale', { immediate: true })
  onEmbossingScaleChanged(): void {
    const projection = this.getActiveProjection();
    if (projection) projection.scaleFactor = this.embossingScale;
    this.checkEmbossingOffset();
    this.storeOffset();
  }

  changeEmbossingScale(): void {
    this.addToHistory();
  }

  oldProjectionValues!: ProjectionSidebarData;
  addToHistory(): void {
    const object = this.selectionList.getSelectedObject();
    const id = this.selectionList.getSelectedObjectUuid();
    if (object && id) {
      const activeCategory = this.modelValue.activeCategory;
      const projection = this.getActiveProjection();
      if (projection) {
        const dataOld: ProjectionSidebarData = this.copySidebarData(
          this.oldProjectionValues
        );
        const dataNew: ProjectionSidebarData = this.copySidebarData(projection);
        this.historyList.add(
          id,
          HistoryOperationType.embossing,
          async () => {
            this.undoRedoSidebarData(object, dataOld, activeCategory);
          },
          async () => {
            this.undoRedoSidebarData(object, dataNew, activeCategory);
          }
        );
        this.bufferSidebarData();
      }
    }
  }

  bufferSidebarData(): void {
    const projection = this.getActiveProjection();
    if (projection) {
      this.oldProjectionValues = this.copySidebarData(projection);
    }
  }

  copySidebarData(data: ProjectionSidebarData): ProjectionSidebarData {
    const colorDefinition: { [p: string]: string[] } = {};
    for (const key of Object.keys(data.colorDefinition)) {
      colorDefinition[key] = data.colorDefinition[key].map((item) => item);
    }
    return {
      projectionCategory: data.projectionCategory,
      aspectRatio: data.aspectRatio,
      scaleFactor: data.scaleFactor,
      offset: { x: data.offset.x, y: data.offset.y },
      colorDefinition: colorDefinition,
      color: data.color,
      embossingDefinition: data.embossingDefinition.map((item) => {
        return {
          name: item.name,
          url: item.url,
          uid: item.uid,
          status: item.status,
        };
      }),
      embossingFile: data.embossingFile,
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  uploadFile(res: any): boolean {
    //const url = URL.createObjectURL(res.file);
    fileContentToBase64(res.file, (encodeString) => {
      if (this.modelValue.activeCategory === ProjectionCategory.logo)
        this.embossingList.length = 0;
      const data: UploadData = {
        name: res.file.name,
        url: encodeString,
        uid: res.file.uid,
      };
      this.embossingList.push(data);
      const projection = this.getActiveProjection();
      if (projection) projection.embossingDefinition = this.embossingList;
      setTimeout(() => {
        this.handleSelectEmbossing(data);
      }, 500);
    });
    return true;
  }

  beforeUpload(file: any): boolean {
    if (isValidFileType(file.name, FileCategory.IMAGE)) {
      return true;
    }
    ElMessage.error((this as any).$t('components.embossing-sidebar.wrongType'));
    return false;
  }

  isDragging = false;
  downPosition = { x: 0, y: 0 };
  downEmbossingOffset = { x: 0, y: 0 };
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  startDrag(event: any): void {
    this.isDragging = true;
    this.downPosition = { x: event.canvasOffsetX, y: event.canvasOffsetY };
    this.downEmbossingOffset = {
      x: this.embossingOffset.x,
      y: this.embossingOffset.y,
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  drag(event: any): void {
    if (this.isDragging) {
      this.embossingOffset.x =
        this.downEmbossingOffset.x +
        (event.canvasOffsetX - this.downPosition.x);
      this.embossingOffset.y =
        this.downEmbossingOffset.y +
        (event.canvasOffsetY - this.downPosition.y);
      this.checkEmbossingOffset();
    }
  }

  getCanvasWidth(): number {
    const embossingLayoutPreview = this.$refs
      .embossingLayoutPreview as Rectangle;
    if (embossingLayoutPreview && embossingLayoutPreview.element)
      return (
        embossingLayoutPreview.element.layoutW -
        this.embossingBorderThickness * 2
      );
    return 0;
  }

  getCanvasHeight(): number {
    const embossingLayoutPreview = this.$refs
      .embossingLayoutPreview as Rectangle;
    if (embossingLayoutPreview && embossingLayoutPreview.element) {
      return (
        embossingLayoutPreview.element.layoutW / this.aspectRatio -
        this.embossingBorderThickness * 2
      );
      //return embossingLayoutPreview.element.layoutH - this.embossingBorderThickness * 2;
    }
    return 0;
  }

  private checkEmbossingOffset(): void {
    const canvasWidth = this.getCanvasWidth();
    let maxX = ((1 - this.embossingScale) * canvasWidth) / 2;
    if (this.embossingScale > 1) {
      maxX = (this.embossingScale * canvasWidth) / 2 - canvasWidth / 2;
    }
    const minX = -maxX;
    const canvasHeight = this.getCanvasHeight();
    const scaleY = this.embossingScale * this.aspectRatio;
    let maxY = ((1 - scaleY) * canvasHeight) / 2;
    if (scaleY > 1) {
      maxY = (scaleY * canvasHeight) / 2 - canvasHeight / 2;
    }
    const minY = -maxY;
    if (this.embossingOffset.x < minX) this.embossingOffset.x = minX;
    if (this.embossingOffset.x > maxX) this.embossingOffset.x = maxX;
    if (this.embossingOffset.y < minY) this.embossingOffset.y = minY;
    if (this.embossingOffset.y > maxY) this.embossingOffset.y = maxY;
  }

  storeOffset(): void {
    const newOffset = {
      x: this.getRelativeOffsetX(),
      y: this.getRelativeOffsetY(),
    };
    const projection = this.getActiveProjection();
    if (
      projection &&
      (projection.offset.x !== newOffset.x ||
        projection.offset.y !== newOffset.y)
    ) {
      projection.offset = newOffset;
    }
  }

  restoreOffset(): void {
    const projection = this.getActiveProjection();
    if (projection) {
      this.setRelativeOffsetX(projection.offset.x);
      this.setRelativeOffsetY(projection.offset.y);
    }
  }

  getRelativeOffsetX(): number {
    const canvasSize = this.getCanvasWidth();
    if (canvasSize > 0) {
      return this.embossingOffset.x / canvasSize;
    }
    return 0;
  }

  getRelativeOffsetY(): number {
    const canvasSize = this.getCanvasHeight();
    if (canvasSize > 0) {
      return this.embossingOffset.y / canvasSize;
    }
    return 0;
  }

  setRelativeOffsetX(value: number): void {
    const canvasSize = this.getCanvasWidth();
    if (canvasSize > 0) {
      this.embossingOffset.x = value * canvasSize;
    }
  }

  setRelativeOffsetY(value: number): void {
    const canvasSize = this.getCanvasHeight();
    if (canvasSize > 0) {
      this.embossingOffset.y = value * canvasSize;
    }
  }

  endDrag(): void {
    if (this.isDragging) {
      this.isDragging = false;
      this.storeOffset();
      this.addToHistory();
    }
  }

  dragOut(): void {
    if (this.isDragging) this.storeOffset();
  }

  handleSelectEmbossing(file: UploadData | null): void {
    const projection = this.getActiveProjection();
    if (file) {
      this.selectedEmbossing = file.uid;
      this.selectedEmbossingFile = file.url;
      if (projection) projection.embossingFile = file.url;
    } else {
      this.selectedEmbossing = -1;
      this.selectedEmbossingFile = '';
      if (projection) projection.embossingFile = null;
    }
    this.addToHistory();
  }

  handleRemoveEmbossing(file: UploadData): void {
    const index = this.embossingList.findIndex((item) => item.uid === file.uid);
    if (file.uid === this.selectedEmbossing) this.handleSelectEmbossing(null);
    if (index > -1) {
      this.embossingList.splice(index, 1);
      const projection = this.getActiveProjection();
      if (projection) projection.embossingDefinition = this.embossingList;
    }
  }

  handleSelectColor(color: string | null): void {
    this.selectedColor = color;
    const projection = this.getActiveProjection();
    if (projection) projection.color = color;
    this.addToHistory();
  }

  handleRemoveColor(category: string, color: string): void {
    if (color === this.selectedColor) this.handleSelectColor(null);
    if (this.colors[category]) {
      const index = this.colors[this.addCategory].indexOf(color);
      if (index > -1) {
        this.colors[this.addCategory].splice(index, 1);
        const projection = this.getActiveProjection();
        if (projection) projection.colorDefinition = this.colors;
      }
    }
  }

  undoRedoSidebarData(
    mesh: THREE.Object3D,
    data: ProjectionSidebarData,
    category: ProjectionCategory
  ): void {
    this.selectObject(mesh, true).then(() => {
      this.modelValue.activeCategory = category;
      for (const projectionItem of this.projectionList) {
        projectionItem.isSelected =
          projectionItem.id === this.modelValue.activeCategory;
      }
      const projection = this.modelValue.projection.find(
        (item) => item.projectionCategory === category
      );
      if (projection) {
        projection.aspectRatio = data.aspectRatio;
        projection.scaleFactor = data.scaleFactor;
        projection.offset = { x: data.offset.x, y: data.offset.y };
        const colorDefinition: { [p: string]: string[] } = {};
        for (const key of Object.keys(data.colorDefinition)) {
          colorDefinition[key] = data.colorDefinition[key].map((item) => item);
        }
        projection.colorDefinition = colorDefinition;
        projection.color = data.color;
        projection.embossingDefinition = data.embossingDefinition.map(
          (item) => {
            return {
              name: item.name,
              url: item.url,
              uid: item.uid,
              status: item.status,
            };
          }
        );
        projection.embossingFile = data.embossingFile;
      }
    });
  }
}

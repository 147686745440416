
import PreviewRendererImg from "@/components/three/PreviewRendererImg.vue";
import { Options, Vue } from "vue-class-component";
import { OrderRequestData } from "@/services/api/orderService";
import { Prop } from "vue-property-decorator";
import { WorkflowModel } from "@/types/ui/WorkflowModel";
import PreviewRenderer from "@/components/three/PreviewRenderer.vue";
import { MeshTreeData, MeshTreeDataGroupItem, MeshTreeDataItem } from "@/types/ui/MeshTreeData";
import { Product } from "@/types/api/Model/Product";
import ProductParameterBalcony from "./productParameters/ProductParameterBalcony.vue";
import ProductParameterCover from "./productParameters/ProductParameterCover.vue";
import ProductParameterCuboid from "./productParameters/ProductParameterCuboid.vue";
import ProductParameterFence from "./productParameters/ProductParameterFence.vue";
import ProductParamterOrthesis from "./productParameters/ProductParameterOrthesis.vue";
import Auth from "@/services/api/auth";
import { Role } from "@/types/enum/user";

@Options({
  components: {
    PreviewRendererImg,
    PreviewRenderer,
    ProductParameterBalcony,
    ProductParameterCover,
    ProductParameterCuboid,
    ProductParameterFence,
    ProductParamterOrthesis
  }
})

export default class OrderSummary extends Vue {
  @Prop() order!: OrderRequestData;
  @Prop() workflowModel!: WorkflowModel;
  @Prop() meshTreeData!: MeshTreeData;
  @Prop({ default: [] }) treeData!: MeshTreeDataItem[];
  @Prop({ default: [] }) colorList!: string[];
  @Prop({ default: [] }) embossingList!: string[];
  @Prop({ default: [] }) embossingColorList!: string[];
  @Prop() product!: Product;

  authentication = Auth.getInstance();
  Role = Role;

  meshGroupedPartItems: {[key: string]: MeshTreeDataGroupItem[]} = {};

  mounted(): void {
    this.meshTreeData.getGroupedPartItems(false).then((list) => {
      this.meshGroupedPartItems = list;
    })
  }
}


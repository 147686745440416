import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    id: "order-form",
    ref: "dataForm",
    model: _ctx.formData,
    "label-position": "top",
    "status-icon": true,
    onValidate: _ctx.formItemValidated,
    onSubmit: _ctx.submitData,
    onReset: _ctx.reset
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["model", "onValidate", "onSubmit", "onReset"]))
}
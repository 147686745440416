import { tr } from "element-plus/es/locale";
import { CustomizeProgress } from "../enum/workflow";
import { MeshTreeData, MeshTreeDataItem } from "./MeshTreeData";

/* eslint-disable @typescript-eslint/no-explicit-any*/
export class WorkflowModel {
  active = 0;
  localeIdentifier: string;
  Progress;
  readonly stepCount: number;
  finished = false;
  navigationStart = false;
  totalPriceArray: Map<string, number[]>;

  public IsForm: boolean;
  public OnClickNextAction: any;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(localeIdentifier: string, progress: any) {
    this.localeIdentifier = localeIdentifier;
    this.Progress = progress;
    this.stepCount = Object.keys(this.Progress).length;
    this.IsForm = false;
    this.OnClickNextAction = this.changeActiveStep;
    this.finished = false;
    this.totalPriceArray = new Map<string, number[]>();
  }

  public changeActiveStep(difference: number): void {
    this.navigationStart = true;
    this.active += difference;
    if (this.active < 0) this.active = 0;
    if (this.active >= this.stepCount) {
      this.active = this.stepCount - 1;
      this.finished = true;
    }
  }

  public isNextStepPossible(): boolean {
    return this.active < this.stepCount && Object.keys(this.Progress)[this.active] !== CustomizeProgress.product;
  }

  public activeProgressName(): string {
    return Object.keys(this.Progress)[this.active].toString();
  }

  public isPrevStepPossible(): boolean {
    return this.active > 0;
  }

  public isLastStep(): boolean {
    return this.active == this.stepCount - 1;
  }

  public initTotalPrice(treeData: MeshTreeData) {
    this.totalPriceArray.clear();

    // price for complete product for each step
    const prices: number[] = [];
    for (let i = 0; i < this.stepCount; i++) {
      prices.push(0.0);
    }
    this.totalPriceArray.set('product', prices);

    // price for each mesh and for each step 
    for(const mesh of treeData.treeData) {
      const prices: number[] = [];
      for (let i = 0; i < this.stepCount; i++) {
        prices.push(0.0);
      }
      this.totalPriceArray.set(mesh.id!, prices);
    }
  }

  public setStepPrice(meshTreeDataItemId: string , step: number, price: number): void {
    const stepArray = this.totalPriceArray.get(meshTreeDataItemId);
    if (stepArray) {
      stepArray[step] = price;
      this.totalPriceArray.set(meshTreeDataItemId, stepArray);
    }
  }

  get totalPrice(): number {
    let sum = 0.0;
    for(const component of this.totalPriceArray) {
      for(const price of component[1]) {
        sum += price;
      }
    }
    return sum;
  }
}

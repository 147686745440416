
import { type Ref, ref, inject, onMounted } from 'vue';
import SidebarTools from "@/components/Tools/SidebarTools.vue";
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { WorkflowModel } from '@/types/ui/WorkflowModel';
import Auth from '@/services/api/auth';
import MeshEditor from './three/MeshEditor.vue';

@Options({
  methods: {ref},
  components: {
    SidebarTools
  },
  emits: ['productQualityChanged', 'toggleOpacity'],
})

export default class CustomHeader extends Vue {
  @Prop({ default: false }) isEditor!: boolean;
  @Prop() workflowModel!: WorkflowModel;
  @Prop({ default: ''}) productName!: string;
  @Prop({ default: null }) authentication!: Auth;
  @Prop({ default: null }) supplier!: string;
  @Prop({ default: 3 }) selectedProductQualityIndex!:number;
  @Prop({ default: null }) meshEditor!: MeshEditor;

  productQualityChanged(selectedProductQuality: string) {
    this.$emit('productQualityChanged', selectedProductQuality);
  }
}

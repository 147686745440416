
import { Options, Vue } from 'vue-class-component';
import MeshTree from '@/components/three/MeshTree.vue';
import { MeshTreeData, MeshTreeDataItem } from '@/types/ui/MeshTreeData';
import OrientationGizmo from '@/components/three/OrientationGizmo.vue';
import MeshEditor from '@/components/three/MeshEditor.vue';
import { FileType3D, UploadCategory } from '@/types/enum/upload';
import { OrderProgress } from '@/types/enum/workflow';
import * as LayoutUtility from '@/utils/layout';
import { Prop, Watch } from 'vue-property-decorator';
import * as modelService from '@/services/api/modelService';
import * as OrderService from '@/services/api/orderWorkflowService';
import * as THREE from 'three';
import ToggleSidebar from '@/components/element-plus/ToggleSidebar.vue';
import Workflow from '@/components/workflow/Workflow.vue';
import { WorkflowModel } from '@/types/ui/WorkflowModel';
import { DefaultUndoRedo } from '@/types/ui/HistoryList';
import { CurveCategory } from '@/types/api/Model/Curve/CurveCategory';

@Options({
  components: {
    MeshEditor,
    OrientationGizmo,
    MeshTree,
    ToggleSidebar,
    Workflow,
  },
  emits: ['update:orderId'],
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class OrderWorkflow extends Vue implements DefaultUndoRedo {
  @Prop() templateId!: number;
  @Prop({ default: null }) orderId!: number | null;
  meshTreeData: MeshTreeData = new MeshTreeData(null, this);
  showTree = true;
  lastUpdateDB = -1;
  fullscreenLoading = false;
  meshEditor!: MeshEditor;

  workflowModel: WorkflowModel = new WorkflowModel(
    'order-progress',
    OrderProgress
  );
  OrderProgress = OrderProgress;

  get allowedCurveCategories(): CurveCategory[] {
    switch (this.activeOrderProgress) {
      case OrderProgress.crop:
        return [CurveCategory.CROP];
      case OrderProgress.zip:
        return [CurveCategory.ZIP];
      case OrderProgress.spacer:
        return [CurveCategory.SPACER];
    }
    return Object.values(CurveCategory);
  }

  get activeOrderProgress(): OrderProgress {
    if (this.workflowModel.active < this.workflowModel.stepCount) {
      LayoutUtility.refresh();
      return Object.values(OrderProgress)[this.workflowModel.active];
    }
    return OrderProgress.setup;
  }

  set activeOrderProgress(value: OrderProgress) {
    this.workflowModel.active = Object.values(OrderProgress).indexOf(value);
  }

  unmounted(): void {
    (this.$router as any).askForChanges = false;
  }

  mounted(): void {
    setTimeout(() => {
      if (this.$refs.meshEditor)
        this.meshEditor = this.$refs.meshEditor as MeshEditor;
    }, 100);
    this.loadFromDB();
  }

  loadFromDB(): void {
    OrderService.checkDataForId(this.orderId, this.templateId).then(
      async (importData) => {
        const orderId = this.orderId
          ? this.orderId
          : importData.orderId
          ? importData.orderId
          : 0;
        if (importData.meshes && importData.meshes.length > 0) {
          const loadChildren = (
            parent: string | MeshTreeDataItem | null,
            children: modelService.MeshExportData[]
          ): void => {
            for (const data of children) {
              let treeData!: MeshTreeDataItem;
              if (data.fileType !== FileType3D.NONE) {
                treeData = this.meshTreeData.addBase64Import(
                  data.uniqueKey,
                  !this.orderId ? null : data.componentId,
                  data.name,
                  data.fileType,
                  data.base64,
                  parent
                    ? parent
                    : (this as any).$t(`enum.upload-category.${data.category}`),
                  data.thumbnailType,
                  data.thumbnail,
                  false,
                  data.color,
                  data.position,
                  data.rotation,
                  data.scale
                );
                treeData.bones = data.bones.map((boneInfo) => {
                  const bone = new THREE.Bone();
                  bone.name = boneInfo.name;
                  bone.position.copy(boneInfo.position);
                  return bone;
                });
              } else {
                treeData = this.meshTreeData.addGroup(
                  data.name,
                  [],
                  data.position,
                  data.rotation,
                  data.scale,
                  parent
                    ? parent
                    : (this as any).$t(`enum.upload-category.${data.category}`),
                  false
                );
                treeData.id = data.uniqueKey;
              }
              loadChildren(
                treeData,
                importData.meshes.filter(
                  (item) => item.parentId === data.uniqueKey
                )
              );
            }
          };

          loadChildren(
            null,
            importData.meshes.filter((item) => item.parentId === null)
          );

          setTimeout(() => {
            if (this.meshEditor) {
              for (const data of importData.meshes) {
                if (data.ffd) {
                  this.meshEditor.importFfdForMeshId(
                    data.uniqueKey,
                    data.ffd.spanCounts,
                    data.ffd.gridPositionList
                  );
                }
                if (data.embossing.length > 0) {
                  this.meshEditor.importEmbossingForMeshId(
                    data.uniqueKey,
                    data.embossing
                  );
                }
              }
            }
          }, 500);

          OrderService.getCurveData(orderId).then((curveImport) => {
            setTimeout(() => {
              if (this.meshEditor) {
                this.meshEditor.importCurves(curveImport);
              }
            }, 500);
          });

          OrderService.getCameraData(orderId).then((cameraImport) => {
            setTimeout(() => {
              if (this.meshEditor) {
                this.meshEditor.importCameras(cameraImport);
              }
            }, 500);
          });

          if (!this.orderId) {
            setTimeout(() => {
              this.saveUpdated();
            }, 500);
          } else {
            this.$emit('update:orderId', orderId);
          }
        }
      }
    );
  }

  updateFromDB(): void {
    const getTreeData = (
      treeItemList: MeshTreeDataItem[],
      componentId: number | null
    ): MeshTreeDataItem | undefined => {
      const result = treeItemList.find(
        (item) => item.componentId === componentId
      );
      if (result) return result;
      for (const item of treeItemList) {
        const itemResult = getTreeData(item.children, componentId);
        if (itemResult) return itemResult;
      }
      return undefined;
    };

    if (this.orderId) {
      OrderService.getMeshData(this.orderId).then((importData) => {
        const selection = this.meshTreeData
          .getSelection()
          .map((selectedItem) => selectedItem.id);
        this.meshTreeData.selectItem(null);
        if (this.orderId) {
          if (importData && importData.length > 0) {
            const loadChildren = (
              parent: string | null,
              children: modelService.MeshExportData[]
            ): void => {
              for (const data of children) {
                let treeData: MeshTreeDataItem | undefined = undefined;
                if (data.fileType !== FileType3D.NONE) {
                  treeData = getTreeData(
                    this.meshTreeData.treeData,
                    data.componentId
                  );
                  //base64 assignment is not enough to update the geometry in editor
                  if (treeData && treeData.base64 !== data.base64) {
                    this.meshTreeData.removeItemTemp(treeData);
                    treeData = this.meshTreeData.addBase64Import(
                      data.uniqueKey,
                      data.componentId,
                      data.name,
                      data.fileType,
                      data.base64,
                      parent
                        ? parent
                        : (this as any).$t(
                            `enum.upload-category.${data.category}`
                          ),
                      data.thumbnailType,
                      data.thumbnail,
                      false,
                      data.color,
                      data.position,
                      data.rotation,
                      data.scale
                    );
                  } else if (treeData) {
                    treeData.base64 = data.base64;
                    treeData.position = data.position;
                    treeData.rotation = data.rotation;
                    treeData.scale = data.scale;
                    treeData.color = data.color;
                    this.meshTreeData.setParent(
                      treeData.uuid,
                      parent
                        ? parent
                        : (this as any).$t(
                            `enum.upload-category.${data.category}`
                          )
                    );
                  }
                } else {
                  treeData = this.meshTreeData.addGroup(
                    data.name,
                    [],
                    data.position,
                    data.rotation,
                    data.scale,
                    parent
                      ? parent
                      : (this as any).$t(
                          `enum.upload-category.${data.category}`
                        ),
                    false
                  );
                  treeData.id = data.uniqueKey;
                }
                if (treeData) {
                  loadChildren(
                    treeData.uuid,
                    importData.filter(
                      (item) => item.parentId === treeData?.uuid
                    )
                  );
                }
              }
            };
            loadChildren(
              null,
              importData.filter((item) => item.parentId === null)
            );
            this.meshTreeData.updateStructure();
          }
        }
        if (selection.length > 0 && selection[0]) {
          const selectedItem = this.meshTreeData.getItemById(selection[0]);
          this.meshTreeData.selectItem(selectedItem);
        }
      });
    }
  }

  //#region save
  private updateTreeItem(
    categoryType: UploadCategory,
    item: MeshTreeDataItem,
    parent: MeshTreeDataItem,
    lastUpdateDB = -1
  ): void {
    if (item.mesh && item.lastUpdateDB >= lastUpdateDB && this.orderId) {
      if (item.id) {
        OrderService.updateMeshData(
          this.orderId,
          this.templateId,
          item.id,
          categoryType,
          item.name,
          item.mesh,
          item.bones,
          parent.mesh ? parent.componentId : null,
          false
        ).then((id) => {
          item.id = id;
        });
      } else {
        OrderService.addMeshData(
          this.orderId,
          this.templateId,
          categoryType,
          item.name,
          item.mesh,
          item.bones,
          parent.mesh ? parent.componentId : null,
          false
        ).then((id) => {
          item.id = id;
        });
      }
      this.updateChildren(categoryType, item, lastUpdateDB);
    }
  }

  private updateChildren(
    categoryType: UploadCategory,
    parent: MeshTreeDataItem,
    lastUpdateDB = -1
  ): void {
    for (const child of parent.children) {
      this.updateTreeItem(categoryType, child, parent, lastUpdateDB);
    }
  }

  private async saveAll(): Promise<void> {
    await this.save();
  }

  private async saveUpdated(): Promise<void> {
    await this.save(this.lastUpdateDB);
  }

  private async save(lastUpdateDB = -1): Promise<void> {
    this.fullscreenLoading = true;
    await setTimeout(() => {
      this.lastUpdateDB = Date.now();
      for (const category of this.meshTreeData.treeData) {
        const categoryType = Object.values(UploadCategory).find(
          (categoryType) =>
            category.name ===
            (this as any).$t(`enum.upload-category.${categoryType}`)
        );
        if (categoryType) {
          this.updateChildren(categoryType, category, lastUpdateDB);
        }
      }
      this.fullscreenLoading = false;
      (this.$router as any).askForChanges = this.hasChanges;
    }, 100);
  }

  get hasChanges(): boolean {
    return this.lastUpdateDB < this.meshTreeData.lastUpdateDB;
  }

  @Watch('meshTreeData.lastUpdateDB', { immediate: false })
  async onMeshTreeDataDBChanged(): Promise<void> {
    (this.$router as any).askForChanges = this.hasChanges;
  }
  //#endregion save

  deleteItem(item: MeshTreeDataItem): void {
    if (item.id && this.orderId)
      OrderService.deleteMeshData(this.orderId, item.id);
  }

  @Watch('showTree', { immediate: true })
  onShowTreeChanged(): void {
    LayoutUtility.refresh();
  }

  @Watch('workflowModel.active', { immediate: true })
  onActiveStepChanged(): void {
    if (this.workflowModel.navigationStart && this.hasChanges)
      this.saveUpdated();
  }

  @Watch('workflowModel.finished', { immediate: true })
  onStepFinished(): void {
    if (this.workflowModel.finished) {
      this.saveUpdated().then(() => {
        this.$router.go(-1);
      });
    }
  }

  @Watch('orderId', { immediate: true })
  onOrderIdChanged(): void {
    this.meshTreeData.modelId = this.orderId;
    this.meshTreeData.historyList.modelId = this.orderId;
    this.meshTreeData.historyList.defaultUndoRedo = this;
  }

  async defaultUndo(): Promise<void> {
    if (this.orderId) {
      await modelService.undo(this.orderId, 1).then(() => {
        //todo: geometry is updated in the backend with a delay. 500 milliseconds are not enough
        setTimeout(() => {
          this.updateFromDB();
        }, 500);
      });
    }
  }

  async defaultRedo(): Promise<void> {
    if (this.orderId) {
      await modelService.redo(this.orderId, 1).then(() => {
        //todo: geometry is updated in the backend with a delay. 500 milliseconds are not enough
        setTimeout(() => {
          this.updateFromDB();
        }, 500);
      });
    }
  }
}

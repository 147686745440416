import * as THREE from 'three';
import { isCustomObject3D } from '@/utils/three/boundingBox';
import { isSpecialObject } from '@/types/enum/three';

/* eslint-disable @typescript-eslint/no-explicit-any*/
export const setColor = (
  object: THREE.Object3D,
  color: string | number
): void => {
  if (object && (object as any).material) {
    const material = (object as any).material;
    material.color = new THREE.Color(color);
  }
  object.children.forEach((child) => {
    if (isCustomObject3D(child)) setColor(child, color);
  });
};

export const getColor = (object: THREE.Object3D): string => {
  if (object && (object as any).material) {
    const material = (object as any).material;
    return `#${material.color.getHexString()}`;
  }
  for (const child of object.children) {
    const color = getColor(child);
    if (color.length > 0) return color;
  }
  return '';
};

export const setOpacity = (object: THREE.Object3D, alpha: number): void => {
  console.log((object as any).material);
  if (object && (object as any).material) {
    const material = (object as any).material;
    material.opacity = alpha / 100.0;
    material.transparent = alpha !== 100;
    material.needsUpdate = true;
  }
  if (object)
    object.children.forEach((child) => {
      if (!isSpecialObject(child.name)) setOpacity(child, alpha);
    });
};

const isWireframe = (object: THREE.Object3D): boolean => {
  if (object && (object as any).material) {
    const material = (object as any).material;
    return material.wireframe;
  }
  return false;
};

const hasWireframe = (object: THREE.Object3D): boolean => {
  if ((object as any).isMesh && object.children.length > 0) {
    for (const child of object.children) {
      if (isWireframe(child)) return true;
    }
  }
  return false;
};

export const setWireframe = (
  object: THREE.Object3D,
  showWireframe: boolean
): void => {
  if (object) {
    if ((object as any).isMesh && showWireframe && !hasWireframe(object)) {
      const wireframeMaterial = new THREE.MeshBasicMaterial({
        color: 0x000000,
        wireframe: true,
        transparent: true,
      });
      const wireframe = new THREE.Mesh(
        (object as any).geometry,
        wireframeMaterial
      );
      object.add(wireframe);
    }
    if ((object as any).isMesh && !showWireframe && hasWireframe(object)) {
      const childIndex = object.children.findIndex((child) =>
        isWireframe(child)
      );
      if (childIndex >= 0) object.children.splice(childIndex, 1);
    }

    object.children.forEach((child) => {
      if (!isWireframe(child)) setWireframe(child, showWireframe);
    });
  }
};

export const setTextureFile = (
  object: THREE.Object3D,
  file: string | null
): void => {
  if (object && (object as any).material) {
    const material = (object as any).material as THREE.MeshBasicMaterial;
    if (file) material.map = new THREE.TextureLoader().load(file);
    else material.map = null;
  }
};

export const setTexture = (
  object: THREE.Object3D,
  texture: THREE.Texture | null
): void => {
  if (object && (object as any).material) {
    const material = (object as any).material as THREE.MeshBasicMaterial;
    material.map = texture;
  }
};

export const getTexture = (object: THREE.Object3D): THREE.Texture | null => {
  if (object && (object as any).material) {
    const material = (object as any).material as THREE.MeshBasicMaterial;
    return material.map;
  }
  return null;
};

export const scaleTexture = (
  object: THREE.Object3D,
  scaleX = 1,
  scaleY = 1,
  offsetX = 0,
  offsetY = 0,
  aspectRatio = 1
): void => {
  const texture = getTexture(object);
  if (texture) {
    const scaleFactorX = 1.0 / scaleX;
    let offsetFactorX = -(scaleFactorX - 1) / 2;
    const scaleFactorY = 1.0 / (scaleY * aspectRatio);
    let offsetFactorY = -(scaleFactorY - 1) / 2;
    offsetFactorX -= scaleFactorX * offsetX;
    offsetFactorY -= scaleFactorY * offsetY;
    texture.repeat.set(scaleFactorX, scaleFactorY);
    texture.offset.set(
      (scaleFactorX - 1 + offsetFactorX) * -1,
      (scaleFactorY - 1 + offsetFactorY) * -1
    );
  }
};

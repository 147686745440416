
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { version } from "../../package.json";

export default class CustomFooter extends Vue {
  @Prop({ default: false }) isEditor!: boolean;

  privacyUri = "";
  copyrightUri = "";
  version = version;
}


import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ElForm } from 'element-plus';

/* eslint-disable @typescript-eslint/no-explicit-any*/
export interface ValidationData {
  [name: string]: any;
}

export enum ValidationFormCall {
  RESET = 'reset',
  CLEAR_VALIDATE = 'clear_validate',
  SUBMIT_DATA = 'submit_data',
}

@Options({
  components: {},
  emits: ['submitDataValid', 'submitDataNotValid', 'reset'],
})
export default class ValidationForm extends Vue {
  @Prop({ default: { stateMessage: '' } }) formData!: ValidationData;
  submitCheckActive = false;

  dataForm(): typeof ElForm {
    return this.$refs.dataForm as typeof ElForm;
  }

  formItemValidated(prop: string): void {
    if (!this.submitCheckActive && prop != 'stateMessage') {
      this.formData.stateMessage = '';
      //if (this.dataForm()) this.dataForm().validateField('stateMessage');
    }
  }

  @Watch('formData', { immediate: true, deep: true })
  onFormDataChanged(value: ValidationData): void {
    if (!('stateMessage' in value)) value.stateMessage = '';
    if (!('call' in value)) value.call = '';

    if (value.call.length > 0) {
      switch (value.call) {
        case ValidationFormCall.RESET:
          this.reset();
          break;
        case ValidationFormCall.CLEAR_VALIDATE:
          this.clearValidate();
          break;
        case ValidationFormCall.SUBMIT_DATA:
          this.submitData();
          break;
      }
      value.call = '';
    }
  }

  public clearValidate(): void {
    this.dataForm()?.clearValidate();
  }

  public reset(): void {
    this.$emit('reset');
    this.clearValidate();
  }

  public async submitData(event: Event | null = null): Promise<void> {
    this.submitCheckActive = true;
    if (event) event.preventDefault();
    this.formData.stateMessage = '';
    const form = this.dataForm();
    form?.validate(async (valid) => {
      if (valid) {
        this.$emit('submitDataValid');
      } else {
        this.$emit('submitDataNotValid');
        this.formData.stateMessage = (this as any).$t(
          'error.vuelidate.validationErrors'
        );
      }
    });
    this.submitCheckActive = false;
  }

  public validateField(field: string): void {
    const form = this.dataForm();
    form?.validateField(field);
  }

  public fieldValue(field: string): any {
    return eval(`this.formData.${field}`);
  }
}

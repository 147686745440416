import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
import { RendererPublicInterface } from 'troisjs';
import * as THREE from 'three';
import * as THREEBoundingBox from '@/utils/three/boundingBox';
import { MeshTreeData, MeshTreeDataItem } from '@/types/ui/MeshTreeData';
import { isSpecialObject } from '@/types/enum/three';
import { DeformationToolType } from '@/components/Tools/DeformationTools.vue';

/* eslint-disable @typescript-eslint/no-explicit-any*/

export interface MeshSelector {
  selectionList: SelectionList;
  selectMesh(meshId: string, type: DeformationToolType): Promise<void>;
}

export interface IntersectionData {
  intersects: boolean;
  point: THREE.Vector3;
  normal: THREE.Vector3;
  vertex: number;
  direction: THREE.Vector3;
}

export class SelectionList {
  data: MeshTreeData;
  troisRenderer: RendererPublicInterface;
  outlinePass!: OutlinePass;
  rayCaster: THREE.Raycaster;
  intersectionMesh: THREE.Object3D | null = null;

  constructor(data: MeshTreeData, troisRenderer: RendererPublicInterface) {
    this.data = data;
    this.troisRenderer = troisRenderer;
    this.rayCaster = new THREE.Raycaster();

    // setup postprocessing
    if (troisRenderer.composer && troisRenderer.scene && troisRenderer.camera) {
      const htmlCanvas = troisRenderer.renderer.domElement as HTMLCanvasElement;
      this.outlinePass = new OutlinePass(
        new THREE.Vector2(htmlCanvas.width, htmlCanvas.height),
        troisRenderer.scene,
        troisRenderer.camera
      );
      this.outlinePass.visibleEdgeColor.set('#555555');
      this.outlinePass.edgeGlow = 1;
      this.outlinePass.edgeStrength = 4;
      this.outlinePass.edgeThickness = 3;
      this.outlinePass.downSampleRatio = 0.5;
      troisRenderer.composer.addPass(this.outlinePass);
    }
  }

  //#region selection
  getSelectedObjects(): THREE.Object3D[] {
    if (this.outlinePass) return this.outlinePass.selectedObjects;
    return [];
  }

  getSelectedObject(): THREE.Object3D | null {
    if (this.getSelectedObjects().length > 0)
      return this.getSelectedObjects()[0];
    return null;
  }

  getSelectedObjectSaveId(): string | null {
    const selection = this.getSelectedObject();
    if (selection && (selection as any).apiData)
      return (selection as any).apiData.id;
    return null;
  }

  getSelectedObjectUuid(): string | null {
    const selection = this.getSelectedObject();
    if (selection && (selection as any).apiData)
      return (selection as any).apiData.uuid;
    return null;
  }

  isSelected(item: MeshTreeDataItem): boolean {
    return (
      item.isSelected &&
      (item.mesh || item.camera) &&
      this.getSelectedObjects().includes(item.mesh)
    );
  }

  async selectObject(
    target: THREE.Object3D | null,
    setSelectionFlag = true,
    deselectPrevious = true,
    triggerUpdate = false
  ): Promise<void> {
    const oldSelection = this.getSelectedObjects();
    if (oldSelection.length > 0) {
      THREEBoundingBox.removeBoundingBox(oldSelection);
    }

    if (target) {
      if (this.outlinePass) this.outlinePass.selectedObjects = [target];

      if (setSelectionFlag)
        await this.data.selectItem(
          (target as any).apiData,
          deselectPrevious,
          triggerUpdate
        );
    } else {
      if (this.outlinePass) this.outlinePass.selectedObjects = [];
      if (setSelectionFlag) await this.data.selectItem(null);
    }
  }

  deselectObject(target: THREE.Object3D): void {
    const index = this.outlinePass.selectedObjects.findIndex(
      (item) => item.uuid === target.uuid
    );
    if (index > -1) this.outlinePass.selectedObjects.splice(index, 1);
  }
  //#endregion selection

  //#region intersection
  isSameIntersectionMesh(event: MouseEvent): boolean {
    const mesh = this.getClickedObject(event);
    if (this.intersectionMesh && mesh)
      return this.intersectionMesh.id === mesh.id;
    return false;
  }

  getIntersection(
    event: MouseEvent,
    center = false,
    mesh: THREE.Object3D | null = null
  ): IntersectionData | null {
    if (!mesh) mesh = this.getClickedObject(event);
    if (!mesh) return null;
    this.intersectionMesh = mesh;
    if (mesh instanceof THREE.Mesh && center) {
      ((mesh as THREE.Mesh).material as THREE.MeshBasicMaterial).side =
        THREE.DoubleSide;
    }

    this.updateRayCaster(event);
    const intersects: THREE.Intersection[] = this.rayCaster.intersectObject(
      mesh,
      false
    );

    if (mesh instanceof THREE.Mesh && center) {
      ((mesh as THREE.Mesh).material as THREE.MeshBasicMaterial).side =
        THREE.FrontSide;
    }

    if (intersects.length > 0) {
      const intersection = {
        intersects: false,
        point: new THREE.Vector3(),
        normal: new THREE.Vector3(),
        vertex: -1,
        direction: this.rayCaster.ray.direction,
      };

      const point = intersects[0].point;
      if (center && intersects.length > 1) {
        const outIntersection = intersects[intersects.length - 1];
        point.add(outIntersection.point).divideScalar(2);
      }
      intersection.point.copy(mesh.worldToLocal(point));

      const normal = intersects[0].face?.normal.clone();
      if (normal) intersection.normal.copy(normal);

      const vertex = intersects[0].face?.a;
      if (vertex) intersection.vertex = vertex;

      intersection.intersects = true;
      return intersection;
    }
    return null;
  }

  checkIntersection(
    event: MouseEvent,
    center = false,
    mesh: THREE.Object3D | null = null
  ): THREE.Vector3 | null {
    const intersection = this.getIntersection(event, center, mesh);
    if (intersection) return intersection.point;
    return null;
  }

  private updateRayCaster(event: MouseEvent): void {
    if (this.troisRenderer.three.camera) {
      const htmlCanvas = this.troisRenderer.renderer
        .domElement as HTMLCanvasElement;
      const x = (event.offsetX / htmlCanvas.width) * 2 - 1;
      const y = 1 - (event.offsetY / htmlCanvas.height) * 2;
      const mouse = new THREE.Vector2(x, y);
      this.rayCaster.setFromCamera(mouse, this.troisRenderer.three.camera);
    }
  }

  getSceneObjects(
    searchName: string | null = null,
    includeSpecialObjects = true
  ): THREE.Object3D[] {
    const sceneObjects: THREE.Object3D[] = [];
    const addChildren = (parent: THREE.Scene | THREE.Object3D): void => {
      const children = parent.children.filter(
        (child) =>
          child.visible &&
          ['Mesh', 'Group', 'Line', 'Object3D'].includes(child.type)
      );
      sceneObjects.push(
        ...children.filter(
          (child) =>
            (!searchName || child.name === searchName) &&
            (includeSpecialObjects || !isSpecialObject(child.name, false))
        )
      );
      children.forEach((child) => {
        addChildren(child);
      });
    };
    if (this.troisRenderer.three.scene)
      addChildren(this.troisRenderer.three.scene);
    return sceneObjects;
  }

  getSceneObjectsByUuid(
    uuid: string | null = null,
    includeSpecialObjects = true
  ): THREE.Object3D[] {
    const sceneObjects: THREE.Object3D[] = [];
    const addChildren = (parent: THREE.Scene | THREE.Object3D): void => {
      const children = parent.children.filter(
        (child) =>
          child.visible &&
          ['Mesh', 'Group', 'Line', 'Object3D'].includes(child.type)
      );
      sceneObjects.push(
        ...children.filter(
          (child) =>
            (!uuid || child.uuid === uuid) &&
            (includeSpecialObjects || !isSpecialObject(child.name, false))
        )
      );
      children.forEach((child) => {
        addChildren(child);
      });
    };
    if (this.troisRenderer.three.scene)
      addChildren(this.troisRenderer.three.scene);
    return sceneObjects;
  }

  getClickedObject(
    event: MouseEvent,
    returnParent = true,
    searchName: string | null = null,
    includeSpecialObjects = true
  ): THREE.Object3D | null {
    this.updateRayCaster(event);
    const sceneObjects: THREE.Object3D[] = this.getSceneObjects(
      searchName,
      includeSpecialObjects
    );
    let intersects: THREE.Intersection[] = [];
    intersects = this.rayCaster.intersectObjects(sceneObjects, false);

    const getParent = (object: THREE.Object3D): THREE.Object3D => {
      if (!object.parent || object.parent?.type === 'Scene') return object;
      return getParent(object.parent);
    };

    if (intersects.length === 0) return null;
    let targetIntersection = intersects.find(
      (intersect) => intersect.object.type === 'Mesh'
    );
    if (!targetIntersection)
      targetIntersection = intersects.find(
        (intersect) => intersect.object.type === 'Group'
      );
    if (!targetIntersection)
      targetIntersection = intersects.find(
        (intersect) => intersect.object.type === 'Line'
      );
    if (targetIntersection && returnParent) {
      return getParent(targetIntersection.object);
    }
    if (targetIntersection) return targetIntersection.object;
    return null;
  }
  //#endregion intersection
}


import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { MeshTreeData, MeshTreeDataItem } from '@/types/ui/MeshTreeData';
import type Node from 'element-plus/es/components/tree/src/model/node';
import type { AllowDropType } from 'element-plus/es/components/tree/src/tree.type';
import { ElMessage, ElMessageBox } from 'element-plus';
import { HistoryOperationType } from '@/types/ui/HistoryList';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const customNodeClass = (data: MeshTreeDataItem) => {
  if (data.isSelected) {
    return 'selected';
  }
  return null;
};

@Options({
  components: {},
  emits: ['deleteItem'],
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class MeshTree extends Vue {
  @Prop() modelValue!: MeshTreeData;
  @Prop({ default: '' }) addUrl!: string;
  currentSelectionKey = null;
  defaultProps = {
    children: 'activeChildren',
    label: 'name',
    class: customNodeClass,
  };
  activeTreeData: MeshTreeDataItem[] = [];

  @Watch('modelValue.lastUpdateStructure', { immediate: false, deep: false })
  async onModelValueStructureChanged(): Promise<void> {
    this.activeTreeData = this.modelValue.getActiveTreeData();
    const checkedKeys: string[] = [];
    const getCheckedKeys = (items: MeshTreeDataItem[]): void => {
      items.forEach((item) => {
        if (item.visible) {
          checkedKeys.push(item.uuid);
        }
        if (item.activeChildren) getCheckedKeys(item.activeChildren);
      });
    };
    getCheckedKeys(this.activeTreeData);
    const tree = this.$refs.tree as any;
    setTimeout(() => {
      if (tree) {
        tree.setCheckedKeys(checkedKeys);
      }
    }, 100);
  }

  handleCheckChange(
    data: MeshTreeDataItem,
    checked: boolean,
    indeterminate: boolean
  ): void {
    data.visible = checked || indeterminate;
  }

  handleNodeClick(data: MeshTreeDataItem, node: Node): void {
    node.expanded = true;
    this.modelValue.selectItem(data, true, true);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  handleNodeDrop(dragNode: any): void {
    const itemId = dragNode.data.uuid;
    const parent = this.modelValue.drag(itemId);
    const newParentId = parent.newParent ? parent.newParent.uuid : null;
    const newIndex = parent.newIndex;
    const oldParentId = parent.oldParent ? parent.oldParent.uuid : null;
    const oldIndex = parent.oldIndex;
    const historyMeshIdList: string[] = [itemId];
    if (parent.newParent) historyMeshIdList.push(parent.newParent.uuid);
    if (parent.oldParent) historyMeshIdList.push(parent.oldParent.uuid);
    this.modelValue.historyList.addList(
      historyMeshIdList,
      HistoryOperationType.hierarchy,
      async () => {
        this.modelValue.setParent(itemId, oldParentId, oldIndex);
        this.onModelValueStructureChanged();
      },
      async () => {
        this.modelValue.setParent(itemId, newParentId, newIndex);
        this.onModelValueStructureChanged();
      }
    );
  }

  allowDrag(draggingNode: Node): boolean {
    return draggingNode.level > 1;
  }

  allowDrop(draggingNode: Node, dropNode: Node, type: AllowDropType): boolean {
    return type === 'inner' || dropNode.level > 1;
  }

  get defaultExpandKeys(): number[] {
    return this.activeTreeData.map((value, index) => {
      return index;
    });
  }

  removeItem(item: MeshTreeDataItem): void {
    ElMessageBox.confirm(
      (this as any).$t('confirm.delete.message'),
      (this as any).$t('confirm.delete.title'),
      {
        confirmButtonText: (this as any).$t('confirm.delete.ok'),
        cancelButtonText: (this as any).$t('confirm.delete.cancel'),
        type: 'warning',
      }
    ).then(() => {
      this.$emit('deleteItem', item);
      this.modelValue.removeItem(item).then((result) => {
        if (!result) {
          ElMessage.error((this as any).$t('error.api.delete'));
        } else {
          ElMessage.success((this as any).$t('success.api.delete'));
        }
      });
    });
  }

  toggleWireframe(item: MeshTreeDataItem): void {
    item.showWireframe = !item.showWireframe;
  }

  toggleOpacity(item: MeshTreeDataItem): void {
    item.opacity = item.opacity === 100 ? 50 : 100;
  }
}


import { Options, Vue } from 'vue-class-component';
import MeshLoader from '@/components/three/MeshLoader.vue';
import { UploadCategory, UploadProgress } from '@/types/enum/upload';
import * as LayoutUtility from '@/utils/layout';
import * as ModelService from '@/services/api/modelService';
import { Prop, Watch } from 'vue-property-decorator';
import Workflow from '@/components/workflow/Workflow.vue';
import { WorkflowModel } from '@/types/ui/WorkflowModel';
import Auth from '@/services/api/auth';
import { User } from '@/types/api/User/User';

@Options({
  components: {
    MeshLoader,
    Workflow,
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class TemplateUpload extends Vue {
  @Prop() templateId!: number | null;

  workflowModel: WorkflowModel = new WorkflowModel(
    'upload-progress',
    UploadProgress
  );
  fullscreenLoading = false;

  authentication = Auth.getInstance();
  user: User | null = null;

  get activeUploadProgress(): UploadProgress {
    if (this.workflowModel.active < this.workflowModel.stepCount) {
      LayoutUtility.refresh();
      return Object.values(UploadProgress)[this.workflowModel.active];
    }
    return UploadProgress.Pivot;
  }

  set activeUploadProgress(value: UploadProgress) {
    this.workflowModel.active = Object.values(UploadProgress).indexOf(value);
  }

  mounted(): void {
    this.authentication.handleAuthentication().then(() => {
      //get user information
      this.user = this.authentication.user;
    });
  }

  @Watch('workflowModel.finished', { immediate: true })
  onStepFinished(): void {
    if (this.workflowModel.finished) {
      this.fullscreenLoading = true;
      setTimeout(() => {
        const loader = this.$refs.meshLoader as MeshLoader;
        if (loader) {
          const result = loader.getResult();
          if (result) {
            ModelService.uploadNewProduct(
              this.templateId,
              loader.loadingData.title,
              loader.selectedInput,
              loader.loadingData.category,
              100
            ).then((result) => {
              if (result) {
                this.$router.replace(`/template-upload?id=${result}`);
                setTimeout(() => {
                  this.$router.go(-1);
                }, 50);
              } else {
                //TODO throw Exception
              }
            });
          }
        }
      }, 100);
    }
  }
}

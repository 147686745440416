
import { Options, Vue } from 'vue-class-component';
import {
  OrientationGizmoGeneric,
  Axis,
} from '@/plugin/OrientationGizmoGeneric';
import { RendererPublicInterface } from 'troisjs';
import * as THREEEnum from '@/types/enum/three';
import * as THREE from 'three';

@Options({
  components: {},
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class OrientationGizmo extends Vue {
  orientationGizmo!: OrientationGizmoGeneric;

  //renderer
  troisRenderer!: RendererPublicInterface;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  init(troisRenderer: RendererPublicInterface): void {
    this.troisRenderer = troisRenderer;
    if (troisRenderer && troisRenderer.camera) {
      // Create our orientation gizmo
      this.orientationGizmo = new OrientationGizmoGeneric(troisRenderer.camera);
      const orientationGizmo = this.$refs.orientationGizmo as HTMLElement;
      orientationGizmo.appendChild(this.orientationGizmo);

      this.orientationGizmo.onAxisSelected = this.selectAxis;
      this.update();
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  selectView(view: THREEEnum.Views) {
    const direction = new THREE.Vector3();
    switch (view) {
      case THREEEnum.Views.front:
        direction.set(0, 0, 1);
        break;
      case THREEEnum.Views.back:
        direction.set(0, 0, -1);
        break;
      case THREEEnum.Views.top:
        direction.set(0, 1, 0);
        break;
      case THREEEnum.Views.bottom:
        direction.set(0, -1, 0);
        break;
      case THREEEnum.Views.right:
        direction.set(1, 0, 0);
        break;
      case THREEEnum.Views.left:
        direction.set(-1, 0, 0);
        break;
      case THREEEnum.Views.custom:
        direction.set(1, 1, 1);
        break;
    }
    if (direction !== new THREE.Vector3()) this.lookDirection(direction);
  }

  private selectAxis(axis: Axis): void {
    this.lookDirection(axis.direction);
  }

  private lookDirection(direction: THREE.Vector3): void {
    if (
      this.troisRenderer &&
      this.troisRenderer.camera &&
      this.troisRenderer.three.cameraCtrl
    ) {
      const distance = this.troisRenderer.camera.position.distanceTo(
        this.troisRenderer.three.cameraCtrl.target
      );
      this.troisRenderer.camera.position.copy(
        direction
          .multiplyScalar(distance)
          .add(this.troisRenderer.three.cameraCtrl.target)
      );
      this.troisRenderer.camera.lookAt(
        this.troisRenderer.three.cameraCtrl.target
      );
    }
  }

  zoom(factor: number): void {
    if (
      this.troisRenderer &&
      this.troisRenderer.camera &&
      this.troisRenderer.three.cameraCtrl
    ) {
      const direction = this.troisRenderer.camera.position
        .clone()
        .sub(this.troisRenderer.three.cameraCtrl.target)
        .normalize();

      const distance = this.troisRenderer.camera.position.distanceTo(
        this.troisRenderer.three.cameraCtrl.target
      );
      this.troisRenderer.camera.position.copy(
        direction
          .multiplyScalar(distance - factor * distance)
          .add(this.troisRenderer.three.cameraCtrl.target)
      );
      this.troisRenderer.camera.lookAt(
        this.troisRenderer.three.cameraCtrl.target
      );
    }
  }

  update(): void {
    if (this.orientationGizmo) this.orientationGizmo.update();
  }
}


import { FenceType } from "@/types/enum/order";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
  emits: ['sizeChanged']
})

export default class ProductParamterFence extends Vue {
  @Prop() properties!: any[];
  @Prop({ default: false }) readonly!: boolean;

  FenceType = FenceType;

  mounted(): void {
    this.properties[0].heightWithoutDeduction = 1100;
  }
}

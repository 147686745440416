import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a105534"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "order-summary" }
const _hoisted_2 = { class: "mt-3" }
const _hoisted_3 = { class: "row border-0" }
const _hoisted_4 = { class: "col mb-2" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col form-label text-secondary mb-1" }
const _hoisted_7 = { class: "col form-label mb-1" }
const _hoisted_8 = {
  key: 0,
  class: "col mb-2"
}
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col form-label text-secondary mb-1" }
const _hoisted_11 = { class: "col form-label mb-1" }
const _hoisted_12 = {
  key: 1,
  class: "col mb-2"
}
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col form-label text-secondary mb-1" }
const _hoisted_15 = { class: "col form-label mb-1" }
const _hoisted_16 = {
  key: 2,
  class: "col mb-2"
}
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "col form-label text-secondary mb-1" }
const _hoisted_19 = { class: "col form-label mb-1" }
const _hoisted_20 = { class: "mt-3" }
const _hoisted_21 = { class: "row border-0" }
const _hoisted_22 = { class: "col mb-2" }
const _hoisted_23 = { class: "row" }
const _hoisted_24 = { class: "col form-label text-secondary mb-1" }
const _hoisted_25 = { class: "col form-label mb-1" }
const _hoisted_26 = { class: "col mb-2" }
const _hoisted_27 = { class: "row" }
const _hoisted_28 = { class: "col form-label text-secondary mb-1" }
const _hoisted_29 = { class: "col form-label mb-1" }
const _hoisted_30 = { class: "col mb-2" }
const _hoisted_31 = { class: "row" }
const _hoisted_32 = { class: "col form-label text-secondary mb-1" }
const _hoisted_33 = { class: "col form-label mb-1" }
const _hoisted_34 = { class: "col mb-2" }
const _hoisted_35 = { class: "row" }
const _hoisted_36 = { class: "col form-label text-secondary mb-1" }
const _hoisted_37 = { class: "col form-label mb-1" }
const _hoisted_38 = { key: 5 }
const _hoisted_39 = { class: "mt-3" }
const _hoisted_40 = { class: "col form-label mb-1" }
const _hoisted_41 = {
  key: 0,
  class: "col form-label text-secondary mb-1"
}
const _hoisted_42 = {
  key: 1,
  class: "col form-label text-secondary mb-1"
}
const _hoisted_43 = {
  key: 2,
  class: "col form-label text-secondary mb-1"
}
const _hoisted_44 = { class: "mt-3" }
const _hoisted_45 = { class: "row border-0" }
const _hoisted_46 = { class: "col mb-2" }
const _hoisted_47 = { class: "row" }
const _hoisted_48 = { class: "col form-label text-secondary mb-1" }
const _hoisted_49 = { class: "col form-label mb-1" }
const _hoisted_50 = { class: "mt-3" }
const _hoisted_51 = { class: "row border-0" }
const _hoisted_52 = { class: "col mb-2" }
const _hoisted_53 = { class: "row" }
const _hoisted_54 = { class: "col form-label text-secondary mb-1" }
const _hoisted_55 = { class: "col form-label mb-1" }
const _hoisted_56 = { class: "row" }
const _hoisted_57 = { class: "col form-label text-secondary mb-1" }
const _hoisted_58 = { class: "col form-label mb-1" }
const _hoisted_59 = { class: "row" }
const _hoisted_60 = { class: "col form-label text-secondary mb-1" }
const _hoisted_61 = { class: "col form-label mb-1" }
const _hoisted_62 = { class: "row border-0" }
const _hoisted_63 = { class: "col mb-2" }
const _hoisted_64 = { class: "row" }
const _hoisted_65 = { class: "col form-label text-secondary mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_parameter_cover = _resolveComponent("product-parameter-cover")!
  const _component_product_parameter_orthesis = _resolveComponent("product-parameter-orthesis")!
  const _component_product_parameter_cuboid = _resolveComponent("product-parameter-cuboid")!
  const _component_product_parameter_balcony = _resolveComponent("product-parameter-balcony")!
  const _component_product_parameter_fence = _resolveComponent("product-parameter-fence")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('components.order-summary.title')), 1),
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('components.order-summary.productDataTitle')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('components.order-summary.product')), 1),
          _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.order?.coverModel), 1)
        ])
      ]),
      (_ctx.colorList.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('components.order-summary.baseColor')), 1),
              _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.colorList.join(', ')), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.embossingList.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('components.order-summary.embossing')), 1),
              _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.embossingList.join(', ')), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.embossingColorList.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('components.order-summary.embossingColor')), 1),
              _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.embossingColorList.join(', ')), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("h3", _hoisted_20, _toDisplayString(_ctx.$t('components.order-summary.contactDataTitle')), 1),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.$t('views.customize.workflow.form.technician')), 1),
          _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.order?.technician), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_26, [
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t('views.customize.workflow.form.company')), 1),
          _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.order?.company), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_30, [
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.$t('views.customize.workflow.form.commissionNo')), 1),
          _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.order?.commissionNo), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_34, [
        _createElementVNode("div", _hoisted_35, [
          _createElementVNode("label", _hoisted_36, _toDisplayString(_ctx.$t('views.customize.workflow.form.mail')), 1),
          _createElementVNode("label", _hoisted_37, _toDisplayString(_ctx.order?.mail), 1)
        ])
      ])
    ]),
    (_ctx.product.type.name.toLowerCase() == 'covers')
      ? (_openBlock(), _createBlock(_component_product_parameter_cover, {
          key: 0,
          properties: [_ctx.order],
          product: _ctx.product,
          readonly: true
        }, null, 8, ["properties", "product"]))
      : _createCommentVNode("", true),
    (_ctx.product.type.name.toLowerCase() == 'orthesen')
      ? (_openBlock(), _createBlock(_component_product_parameter_orthesis, {
          key: 1,
          properties: [_ctx.order],
          product: _ctx.product,
          readonly: true
        }, null, 8, ["properties", "product"]))
      : _createCommentVNode("", true),
    (_ctx.product.type.name.toLowerCase() == 'möbel' ||
          _ctx.product.type.name.toLowerCase() == 'autos' ||
          _ctx.product.type.name.toLowerCase() == 'gebäude')
      ? (_openBlock(), _createBlock(_component_product_parameter_cuboid, {
          key: 2,
          properties: [_ctx.order],
          readonly: true
        }, null, 8, ["properties"]))
      : _createCommentVNode("", true),
    (_ctx.product.type.name.toLowerCase() == 'balkone')
      ? (_openBlock(), _createBlock(_component_product_parameter_balcony, {
          key: 3,
          properties: [_ctx.order],
          readonly: true
        }, null, 8, ["properties"]))
      : _createCommentVNode("", true),
    (_ctx.product.type.name.toLowerCase() == 'zäune')
      ? (_openBlock(), _createBlock(_component_product_parameter_fence, {
          key: 4,
          properties: [_ctx.order],
          readonly: true
        }, null, 8, ["properties"]))
      : _createCommentVNode("", true),
    (_ctx.authentication?.roles?.includes(_ctx.Role.Supplier) || _ctx.authentication?.roles?.includes(_ctx.Role.Reseller))
      ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
          _createElementVNode("h3", _hoisted_39, _toDisplayString(_ctx.$t('components.order-summary.parts')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.meshGroupedPartItems), (part) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "row",
              key: part[0]
            }, [
              _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.$t('components.part-selection.' + _ctx.product.type.name.toLowerCase() + '.' + part[0]) ? part[1].reduce((sum, current) => sum + current.cnt, 0) + 'x ' + _ctx.$t('components.part-selection.' + _ctx.product.type.name.toLowerCase() + '.' + part[0]) : part[1].length + 'x ' + part[0]), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(part[1], (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.meshTreeDataItem.name
                }, [
                  (item.meshTreeDataItem.size?.width)
                    ? (_openBlock(), _createElementBlock("label", _hoisted_41, _toDisplayString(item.cnt + 'x ' + Math.ceil(item.meshTreeDataItem.size?.width) + ' mm'), 1))
                    : _createCommentVNode("", true),
                  (item.meshTreeDataItem.size?.height)
                    ? (_openBlock(), _createElementBlock("label", _hoisted_42, _toDisplayString(Math.ceil(item.meshTreeDataItem.size?.height) + ' mm'), 1))
                    : _createCommentVNode("", true),
                  (item.meshTreeDataItem.size?.depth)
                    ? (_openBlock(), _createElementBlock("label", _hoisted_43, _toDisplayString(Math.ceil(item.meshTreeDataItem.size?.depth) + ' mm'), 1))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("h3", _hoisted_44, _toDisplayString(_ctx.$t('components.order-form.remarks')), 1),
    _createElementVNode("div", _hoisted_45, [
      _createElementVNode("div", _hoisted_46, [
        _createElementVNode("div", _hoisted_47, [
          _createElementVNode("label", _hoisted_48, _toDisplayString(_ctx.$t('components.order-form.remarks')), 1),
          _createElementVNode("label", _hoisted_49, _toDisplayString(_ctx.order?.remarks), 1)
        ])
      ])
    ]),
    _createElementVNode("h3", _hoisted_50, _toDisplayString(_ctx.$t('components.order-summary.priceTitle')), 1),
    _createElementVNode("div", _hoisted_51, [
      _createElementVNode("div", _hoisted_52, [
        _createElementVNode("div", _hoisted_53, [
          _createElementVNode("label", _hoisted_54, _toDisplayString(_ctx.$t('components.order-summary.productPrice')), 1),
          _createElementVNode("label", _hoisted_55, _toDisplayString(_ctx.workflowModel.totalPrice) + " €", 1)
        ]),
        _createElementVNode("div", _hoisted_56, [
          _createElementVNode("label", _hoisted_57, _toDisplayString(_ctx.$t('components.order-summary.ust')), 1),
          _createElementVNode("label", _hoisted_58, _toDisplayString(_ctx.workflowModel.totalPrice * 0.2) + " €", 1)
        ]),
        _createElementVNode("div", _hoisted_59, [
          _createElementVNode("label", _hoisted_60, _toDisplayString(_ctx.$t('components.order-summary.totalPrice')), 1),
          _createElementVNode("label", _hoisted_61, _toDisplayString(_ctx.workflowModel.totalPrice * 1.2) + " €", 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_62, [
      _createElementVNode("div", _hoisted_63, [
        _createElementVNode("div", _hoisted_64, [
          _createElementVNode("label", _hoisted_65, _toDisplayString(_ctx.$t('components.order-summary.shippingCosts')) + ": " + _toDisplayString(_ctx.$t('components.order-summary.shippingCostsInfo')), 1)
        ])
      ])
    ])
  ]))
}
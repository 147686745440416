
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  emits: ['update:modelValue'],
})
export default class ToggleSidebar extends Vue {
  @Prop({ default: true }) modelValue!: boolean;
  @Prop({ default: true }) useLeftSidebar!: boolean;
}


import { ProductQuality as ProductQualityType } from '@/types/enum/template';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
  components: {},
  emits: ['productQualityChanged'],
})
export default class ProductQuality extends Vue {
  @Prop({ default: 3 }) selectedProductQualityIndex!: number

  ProductQualityType = ProductQualityType;
  selectedProductQuality = ProductQualityType.low;

  currentProductQualityIndex = 3;

  mounted(): void {
      this.currentProductQualityIndex = this.selectedProductQualityIndex;
  }

  onProductQualityChanged(): void {
    switch (this.currentProductQualityIndex) {
      case 3:
        this.selectedProductQuality = ProductQualityType.low;
        break;
      case 2:
        this.selectedProductQuality = ProductQualityType.middle;
        break;
      case 1:
        this.selectedProductQuality = ProductQualityType.high;
        break;
      case 0:
        this.selectedProductQuality = ProductQualityType.original;
        break;
      default:
        this.selectedProductQuality = ProductQualityType.low;
    }

    this.$emit('productQualityChanged', this.selectedProductQuality);
  }
}


import {MeshTreeData, MeshTreeDataItem} from "@/types/ui/MeshTreeData";
import {Modal} from "bootstrap";
import {onMounted, ref, watch} from "vue";
import {RGB} from "@/types/api/Utility/RGB";
import PartSelection from "@/components/PartSelection.vue";
import RoundedButton from "@/components/RoundedButton.vue";
import ChangeStepTools from "@/components/Tools/ChangeStepTools.vue";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {
    RoundedButton,
    PartSelection
  },
  emits: ['mesh-color-selected', 'mesh-embossing-color-selected', 'part-selected', 'mesh-texture-color-selected'],
})

export default class ColorFooter extends Vue {
  @Prop() selectedMeshEmbossingColor!: RGB;
  @Prop() selectedMeshColor!: RGB;
  @Prop() selectedMeshTextureColor!: RGB;
  @Prop() meshEmbossingColors!: RGB[];
  @Prop() meshColors!: RGB[];
  @Prop() meshTextureColors!: RGB[];
  @Prop() meshTreeData!: MeshTreeData;
  @Prop() productTypeString!: string;

  showMeshColorMenu = false;
  showMeshEmbossingColorMenu = false;
  showChangeStepMenu = false;
  partSelectionModalDialog: any = null;

  mounted() {
    // Initialize modal dialog
    const modalDialogOptions = {};
    this.partSelectionModalDialog = new Modal('#part-selection-modal-dialog', modalDialogOptions);

    // Enable vertical scrolling with mouse wheel
    const meshColorSelection = document.getElementById("mesh-color-selection");
    meshColorSelection?.addEventListener("wheel", (e: WheelEvent) => {
      if (e.deltaY > 0) {
        meshColorSelection.scrollLeft += 50;
      } else {
        meshColorSelection.scrollLeft -= 50;
      }
    });
    const meshEmbossingColorSelection = document.getElementById("mesh-embossing-color-selection");
    meshEmbossingColorSelection?.addEventListener("wheel", (e: WheelEvent) => {
      if (e.deltaY > 0) {
        meshEmbossingColorSelection.scrollLeft += 50;
      } else {
        meshEmbossingColorSelection.scrollLeft -= 50;
      }
    });
  }
}





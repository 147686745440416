
import {Product} from "@/types/api/Model/Product";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ProductParameterCover from "@/components/productParameters/ProductParameterCover.vue";
import ProductParameterCuboid from "@/components/productParameters/ProductParameterCuboid.vue";
import { File } from "@/types/api/Utility/File";
import { Body_Attribute } from "@/types/api/Patient/BodyAttribute";
import ProductParameterBalcony from "@/components/productParameters/ProductParameterBalcony.vue";
import ProductParameterFence from "@/components/productParameters/ProductParameterFence.vue";
import ProductParameterOrthesis from "@/components/productParameters/ProductParameterOrthesis.vue";


@Options({
  components: {
    ProductParameterCover,
    ProductParameterCuboid,
    ProductParameterBalcony,
    ProductParameterFence,
    ProductParameterOrthesis
  },
  emits: ['buttonClicked', 'zipChanged'],
})

export default class ProductParamter extends Vue {
  @Prop() product!: Product;
  @Prop() properties!: any[];
  @Prop({ default: false }) readonly!: boolean

  selectedBodyAttribute: Body_Attribute | null = null;

  selected(bodyAttribute: Body_Attribute): void {
    this.selectedBodyAttribute = bodyAttribute as Body_Attribute;
  }
}

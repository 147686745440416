import { User as AuthentikUser, UserManager, type UserManagerSettings } from 'oidc-client-ts';
import * as UserService from '@/services/api/userService';
import { User } from '@/types/api/User/User';
import { Role } from '@/types/enum/user';
import { Domain } from '@/types/api/Business/Domain';
import { Supplier } from '@/types/api/User/Supplier';
import { Reseller } from '@/types/api/User/Reseller';

export default class Auth {
  private userManager: UserManager | null = null;
  private static instance: Auth;
  private _user: User | null = null;
  private _roles: Role[] | null = null;
  private _domain: Domain | null = null;

  /**
   * Settings for the UserManager object.
   */
  settings: UserManagerSettings = {
    authority: process.env.VUE_APP_AUTHROITY_URL,
    client_id: process.env.VUE_APP_CLIENT_KEY,
    redirect_uri: process.env.VUE_APP_REDIRECT_URL,
    response_type: process.env.VUE_APP_REPONSE_TYPE,
    scope: process.env.VUE_APP_SCOPE,
    client_secret: process.env.VUE_APP_SECRET,
    post_logout_redirect_uri: process.env.VUE_APP_REDIRECT_URL,
    revokeTokensOnSignout: true
  };

  public get user(): User | null {
    return this._user;
  }

  public get roles(): Role[] | null {
    return this._roles;
  }

  public get domain(): Domain | null {
    return this._domain;
  }

  public isAuthenticated(): boolean {
    return this._user != null;
  }

  constructor() {
    this.fetchUserManager();
  }

  public static getInstance(): Auth {
    if (Auth.instance === undefined) {
      Auth.instance = new Auth();
    }
    return Auth.instance;
  }

  /**
   * Tries to find an UserManager instance in the local storage. If not found, creates a
   * new instance.
   */
  private fetchUserManager(): void {
    // Search for instance in local storage.
    const storageKeys = Object.keys(localStorage);
    const userManagerKey = storageKeys.find((key) => key.startsWith('oidc.'));
    const userManagerJson = userManagerKey
      ? localStorage.getItem(userManagerKey)
      : null;
    const userManagerObject = userManagerJson
      ? new UserManager(JSON.parse(userManagerJson))
      : null;
    // No instance found, create a new one.
    if (userManagerObject == null) {
      this.userManager = new UserManager(this.settings);
      return;
    }
    this.userManager = userManagerObject;
  }

  /**
   * Redirects to log in. Should be called from a button.
   * @param event Event (Button Press)
   */
  public useAuth(event: Event): void {
    event.preventDefault();
    // Invalid user manager, abort.
    if (this.userManager == null) {
      this.fetchUserManager();
      return;
    }

    this.userManager.signinRedirect();
  }

  /**
   * The basic authentication flow. First check if user cookie exists and validates. If there are any problems
   * the user will be redirected to the login page.
   * @returns Promise<void> 
   */
  public async handleAuthentication(supplierUrl: string | null = null): Promise<void> {
    if (supplierUrl !== null) {
      this._domain = await UserService.getDomainByUrlSuffix(supplierUrl);
      return;
    }

    // Invalid user manager, abort
    if (this.userManager == null) {
      this.fetchUserManager();
      return;
    }
    // Try to get saved user object.
    const authentikUser = await this.userManager.getUser();
    // Check token expiring date.
    if (authentikUser &&
      authentikUser.expires_at &&
      authentikUser.expires_at > Date.now() / 1000) {
      await this.authorizeUser(authentikUser);
      return;
    }
    // Redirect user.
    await this.userManager.signinRedirectCallback().then(async authentikUser => {
      // User authenticated.
      await this.authorizeUser(authentikUser);
    }).catch(err => {
      // User unauthenticated.
      console.error(err);
      //this.userManager?.signinRedirect();
    });
  }

  public async handleLogin() {
    this.userManager?.signinRedirect();
  }

  private async authorizeUser(authentikUser: AuthentikUser): Promise<void> {
    if (authentikUser.id_token) {
      localStorage.setItem('token', authentikUser.id_token);
    }

    const stringRoles = authentikUser.profile.groups as string[];
    if (stringRoles && stringRoles.length > 0) {
      this._roles = []
      stringRoles.forEach(stringRole => {
        this._roles?.push(Role[stringRole]);
      });
    }

    if (this._roles?.includes(Role.Supplier)) {
      this._user = await UserService.getSupplierByEmail(authentikUser.profile.email!);
      this._domain = (this._user as Supplier)?.domains[0];
    } 
    else if (this._roles?.includes(Role.Reseller)) {
      this._user = await UserService.getResellerByEmail(authentikUser.profile.email!);
      this._domain = (this._user as Reseller)?.supplier?.domains[0];
    }
    else {
      this._user = await UserService.getUserByEmail(authentikUser.profile.email!);
    }
  }

  /**
   * Handles the log out of the user via a button.
   */
  async handleLogout() {
    // Invalid user manager, abort
    if (this.userManager == null) {
      this.fetchUserManager();
      return;
    }
    // Try to get saved user object.
    const authentikUser = await this.userManager.getUser();
    // Check token expiring date.
    if (!authentikUser) {
      return;
    }

    await this.userManager.removeUser();
    await this.userManager.signoutRedirect({
      id_token_hint: authentikUser.id_token,
      post_logout_redirect_uri: this.settings.post_logout_redirect_uri,
      state: authentikUser.state
    });

    this._user = null;
    this.userManager.clearStaleState();
  }
}
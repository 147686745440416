
import { BalconyType, MountingType, PaneelType } from "@/types/enum/order";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Check, Close, Connection } from '@element-plus/icons-vue';
import { shallowRef } from "vue";

@Options({
  components: {},
  emits: ['sizeChanged']
})

export default class ProductParamterBalcony extends Vue {
  @Prop() properties!: any[];
  @Prop({ default: false }) readonly!: boolean;

  BalconyType = BalconyType;
  MountingType = MountingType;
  PaneelType = PaneelType;

  depthConnected = true;

   Check = shallowRef(Check);
  Close = shallowRef(Close);
  Connection = shallowRef(Connection);

  mountingTypeChanged() :void {
    if (this.properties[0].paneelType == PaneelType.portrait) {
      this.properties[0].mountingType = MountingType.front;
    }

    if(this.properties[0].mountingType == MountingType.front && 
        this.properties[0].paneelType == PaneelType.landscape) {
      this.properties[0].heightWithoutDeduction = 960;
    } else if(this.properties[0].mountingType == MountingType.bottom &&
        this.properties[0].paneelType == PaneelType.landscape) {
      this.properties[0].heightWithoutDeduction = 965;
    } else if (this.properties[0].mountingType == MountingType.front && 
        this.properties[0].paneelType == PaneelType.portrait) {
      this.properties[0].heightWithoutDeduction = 1645;
    }
  }
}

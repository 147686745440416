
import { Options, Vue } from 'vue-class-component';
import * as modelService from '@/services/api/modelService';
import { Model, Model2 } from '@/types/api/Model/Model';
import { FileTypeImage } from '@/types/enum/upload';
import PreviewRenderer from '@/components/three/PreviewRenderer.vue';
import PreviewRendererImg from '@/components/three/PreviewRendererImg.vue';
import SideMenu from '@/components/workflow/SideMenu.vue';
import ToggleSidebar from '@/components/element-plus/ToggleSidebar.vue';
import { ElMessage, ElMessageBox } from 'element-plus';

@Options({
  components: {
    SideMenu,
    ToggleSidebar,
    PreviewRenderer,
    PreviewRendererImg
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class OrderOverview extends Vue {
  modelList: Model2[] = [];
  templateList: Model2[] = [];
  FileTypeImage = FileTypeImage;
  showMenu = true;
  searchText = '';
  modelsLoading = false;

  mounted(): void {
    this.modelsLoading = true;
    modelService.getModelOverviewList().then(async (result) => {
      if (result) {
        const modelList = result.filter((item) => item.basedOn);
        const templateList = result.filter((item) => !item.basedOn);

        this.modelList = modelList;
        this.templateList = templateList;
      } else {
        this.modelList = [];
        this.templateList = [];
      }
      this.modelsLoading = false;
    });
  }

  get filterList(): Model2[] {
    if (this.searchText.length > 0)
      return this.modelList.filter(
        (item) =>
          item.product &&
          item.product.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    return this.modelList;
  }

  convertToUrl(model: Model): string | null {
    if (
      model.product &&
      model.product.thumbnail
    ) {
      return model.product.thumbnail.content;
    }
    return null;
  }

  deleteModel(modelId: number): void {
    ElMessageBox.confirm(
      (this as any).$t('confirm.delete.message'),
      (this as any).$t('confirm.delete.title'),
      {
        confirmButtonText: (this as any).$t('confirm.delete.ok'),
        cancelButtonText: (this as any).$t('confirm.delete.cancel'),
        type: 'warning',
      }
    ).then(() => {
      modelService.deleteModel(modelId).then((result) => {
        if (!result) {
          ElMessage.error((this as any).$t('error.api.delete'));
        } else {
          ElMessage.success((this as any).$t('success.api.delete'));
        }
      });
      const index = this.modelList.findIndex(
        (item) => item.modelId === modelId
      );
      if (index > -1) {
        this.modelList.splice(index, 1);
      }
    });
  }

  displayModel(model: Model, event: PointerEvent): void {
    const path = event.composedPath();
    if (!path.find((item) => (item as any).id === 'deleteModel'))
      this.$router.push(
        `/order-workflow?templateId=${model.basedOn.modelId}&orderId=${model.modelId}`
      );
  }

  createNewModel(model: Model, event: PointerEvent): void {
    const path = event.composedPath();
    if (!path.find((item) => (item as any).id === 'deleteModel'))
      this.$router.push(`/order-workflow?templateId=${model.modelId}`);
  }
}

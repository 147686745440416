
import ProductQuality from "./three/ProductQuality.vue";
import { WorkflowModel } from "@/types/ui/WorkflowModel";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import * as OrderService from '@/services/api/orderService';
import Auth from "@/services/api/auth";
import { Role } from "@/types/enum/user";

@Options({
  components: {
    ProductQuality
  },
  emits: ['continue-clicked', 'back-clicked', 'confirmOfferPdf'],
})

export default class NavigationBar extends Vue {
  @Prop() workflowModel!: WorkflowModel;
  @Prop({ default: '' }) supplier!: string;
  @Prop({ default: -1 }) selectedModelId!: number;
  @Prop({ default: null }) offerData!: OrderService.OrderRequestData;
  @Prop({ default: null}) screenshotSrc!: string[];

  authentication = Auth.getInstance();

  Role = Role;

  async createPartListPdf(): Promise<void> {
    const downloadPdfBase64 = await OrderService.createPdfPartList(this.selectedModelId!, this.authentication.domain?.domainId ?? 2)
    const pdfPartListHTMLElement = (document.getElementById('pdf-part-list') as HTMLAnchorElement);
    if (pdfPartListHTMLElement) {
      pdfPartListHTMLElement.href = downloadPdfBase64.toString();
      pdfPartListHTMLElement.click();
    }
  }


}


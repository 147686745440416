
import { Options, Vue } from 'vue-class-component';
import { MeshTreeData, MeshTreeDataItem } from '@/types/ui/MeshTreeData';
import { RendererPublicInterface } from 'troisjs';
import { SelectionList } from '@/types/ui/SelectionList';
import { ViewCtrl } from '@/types/ui/ViewCtrl';
import { EditorMode } from '@/types/ui/EditorMode';
import { Prop, Watch } from 'vue-property-decorator';
import * as THREE from 'three';
import * as THREEEnum from '@/types/enum/three';
import { SpecialObjectName, SpecialObjectPrefix } from '@/types/enum/three';
import { DragControls } from 'three/examples/jsm/controls/DragControls';
import { CurveTool } from '@/types/enum/editor';
import TransformTools from '@/components/Tools/TransformTools.vue';
import { ElForm } from 'element-plus';
import {
  HistoryInfo,
  HistoryList,
  HistoryOperationType,
} from '@/types/ui/HistoryList';
import * as THREEBoundingBox from '@/utils/three/boundingBox';
import { CurveCategory } from '@/types/api/Model/Curve/CurveCategory';
import { CurveType } from '@/types/api/Model/Curve/CurveType';
import { CurveExportData } from '@/services/api/modelService';

interface CurveData {
  curveId: number | null;
  category: CurveCategory;
  name: string;
  closed: boolean;
  type: CurveType;
}

interface CurveDescription {
  info: CurveData;
  target: THREE.Object3D;
  mesh: THREE.Object3D;
  curve: THREE.CatmullRomCurve3;
  geometry: THREE.BufferGeometry;
  curvePoints: THREE.Vector3[];
  pointList: THREE.Object3D[];
  length: number;
  pointCount: number;
}

const minCurvePoints = 50;

@Options({
  components: { TransformTools },
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class CurveTools extends Vue implements HistoryInfo {
  //#region properties
  @Prop() modelValue!: MeshTreeData;
  @Prop() troisRenderer!: RendererPublicInterface;
  @Prop() editorMode!: EditorMode;
  @Prop() selectionList!: SelectionList;
  @Prop() viewCtrl!: ViewCtrl;
  @Prop({ default: true }) canCurve!: boolean;
  @Prop() readonly selectObject!: (
    target: THREE.Object3D | null,
    setSelectionFlag: boolean
  ) => void;
  @Prop({ default: Object.values(CurveCategory) })
  allowedCurveCategories!: CurveCategory[];
  @Prop() historyList!: HistoryList;

  //history
  lastUpdateTime = -1;

  //curve
  curveList: CurveDescription[] = [];
  curveData: CurveData = {
    curveId: null,
    category: this.getDefaultCategory(),
    name: '',
    closed: false,
    type: CurveType.CENTRIPETAL,
  };
  ctrl_pt_geom!: THREE.SphereGeometry;
  ctrl_pt_material!: THREE.MeshLambertMaterial;

  //drag
  dragControls!: DragControls;
  dragObject: THREE.Object3D | null = null;
  dragCurve: CurveDescription | null = null;
  intersectionPoint: THREE.Vector3 | null = null;

  //tool
  curveTool: CurveTool = CurveTool.move;
  isModifyTypeAdd = false;
  isModifyTypeRemove = false;
  isModifyTypeMove = false;

  // transform
  transformTools: TransformTools | null = null;

  // enums for use in template section
  CurveType = CurveType;
  CurveTool = CurveTool;

  //#endregion properties

  //#region init
  mounted(): void {
    this.ctrl_pt_geom = new THREE.SphereGeometry(3);
    this.ctrl_pt_material = new THREE.MeshLambertMaterial({ color: 0x4d4dff });
    this.setupDrag();
    this.setCurveTool(CurveTool.move);

    this.troisRenderer.renderer.domElement.addEventListener(
      'pointerdown',
      this.onPointerDown
    );
    this.troisRenderer.renderer.domElement.addEventListener(
      'mousemove',
      this.onPointerMove
    );
    this.troisRenderer.renderer.domElement.addEventListener(
      'pointerup',
      this.onPointerUp
    );

    setTimeout(() => {
      if (this.$refs.transformTools) {
        this.transformTools = this.$refs.transformTools as TransformTools;
        this.transformTools.transformControl.addEventListener('mouseUp', () => {
          this.saveTransform();
        });
      }
    }, 100);
  }

  unmounted(): void {
    this.troisRenderer.renderer.domElement.removeEventListener(
      'pointerdown',
      this.onPointerDown
    );
    this.troisRenderer.renderer.domElement.removeEventListener(
      'mousemove',
      this.onPointerMove
    );
    this.troisRenderer.renderer.domElement.removeEventListener(
      'pointerup',
      this.onPointerUp
    );
    if (this.transformTools) {
      this.transformTools.transformControl.removeEventListener(
        'mouseUp',
        () => {
          this.saveTransform();
        }
      );
    }
    this.dragControls.removeEventListener('drag', this.drag);
    this.dragControls.removeEventListener('dragstart', this.dragStart);
    this.dragControls.removeEventListener('dragend', this.dragEnd);
  }

  @Watch('lastUpdateTime', { immediate: true })
  onDataChanged(): void {
    if (this.lastUpdateTime > -1) {
      const mesh = this.selectionList.getSelectedObject();
      this.modelValue.updateDB((mesh as any).apiData.uuid);
    }
  }

  canCreateMeshCurve = true;
  showAddCurveSettings(): void {
    const selection = this.selectionList.getSelectedObject();
    const apiData = (selection as any)?.apiData;
    this.canCreateMeshCurve =
      selection !== null &&
      apiData !== null &&
      !apiData.name.startsWith(SpecialObjectPrefix.plane) &&
      !apiData.name.startsWith(SpecialObjectPrefix.curve);
  }

  setCurveTool(type: CurveTool): void {
    this.curveTool = type;
    switch (type) {
      case CurveTool.move:
        break;
      case CurveTool.add:
        break;
      case CurveTool.remove:
        break;
    }
    this.isModifyTypeMove = this.isCurveTool(CurveTool.move);
    this.isModifyTypeAdd = this.isCurveTool(CurveTool.add);
    this.isModifyTypeRemove = this.isCurveTool(CurveTool.remove);
    if (this.transformTools) {
      this.transformTools.onCanDisableTransformChanged();
    }
  }

  isCurveTool(type: CurveTool): boolean {
    if (this.curveTool) {
      return this.curveTool === type.toString();
    }
    return false;
  }

  canEditCurve(): boolean {
    if (this.transformTools)
      return this.canCurve && this.transformTools.isModifyTypeNone;
    return this.canCurve;
  }

  activeCurve: CurveDescription | null = null;
  oldCurvePoints: THREE.Vector3[] = [];
  curvePointsChanged = false;
  onPointerDown(event: MouseEvent): void {
    if (!this.canEditCurve()) return;
    const selection = this.selectionList.getSelectedObject();
    if (selection) {
      const selectedCurve = this.getCurveForLine(selection);
      if (selectedCurve) this.activeCurve = selectedCurve;
    }
    this.oldCurvePoints = [];
    this.curvePointsChanged = false;
    if (this.activeCurve)
      this.oldCurvePoints = this.activeCurve.pointList.map((point) =>
        point.position.clone()
      );
    const clickPoint = this.selectionList.getClickedObject(
      event,
      false,
      SpecialObjectName.CurvePoint
    );
    switch (this.curveTool) {
      case CurveTool.move:
        break;
      case CurveTool.add:
        if (this.activeCurve && this.activeCurve.target) {
          this.intersectionPoint = this.selectionList.checkIntersection(
            event,
            false,
            this.activeCurve.target
          );
          if (this.intersectionPoint) {
            const list = this.displayCurvePoints(this.activeCurve.mesh, [
              this.intersectionPoint,
            ]);
            const lineIndex = this.isPointOnActiveCurve(this.intersectionPoint);
            if (lineIndex > -1) {
              this.activeCurve.curve.points.splice(
                lineIndex,
                0,
                this.intersectionPoint
              );
              this.activeCurve.pointList.splice(lineIndex, 0, ...list);
            } else {
              this.activeCurve.curve.points.push(this.intersectionPoint);
              this.activeCurve.pointList.push(...list);
            }
            CurveTools.displayCurve(this.activeCurve);
            this.curvePointsChanged = true;
          }
        }
        break;
      case CurveTool.remove:
        if (this.activeCurve && clickPoint) {
          const clickIndex = this.activeCurve.pointList.findIndex(
            (item) => item.uuid === clickPoint.uuid
          );
          if (clickIndex > -1) {
            this.removeFromDrag([clickPoint]);
            this.activeCurve.curve.points.splice(clickIndex, 1);
            this.activeCurve.pointList.splice(clickIndex, 1);
            CurveTools.displayCurve(this.activeCurve);
            const line = clickPoint.parent;
            if (line) {
              const linePointIndex = line.children.findIndex(
                (item) => item.uuid === clickPoint.uuid
              );
              if (linePointIndex > -1) {
                line.children.splice(linePointIndex, 1);
              }
            }
            this.curvePointsChanged = true;
          }
        }
        break;
    }
  }

  private isPointOnActiveCurve(searchPoint: THREE.Vector3): number {
    const bbox = this.getSceneBox();
    const measure = this.getBoxSize(bbox);
    const maxDistance = measure.x / 50;
    if (this.activeCurve && this.activeCurve.curve.points.length > 2) {
      let pointIndex = 0;
      const points = this.activeCurve.curve.getPoints(
        this.activeCurve.pointCount
      );
      for (let i = 1; i < points.length; i++) {
        const curvePoint = this.activeCurve.curve.points[pointIndex];
        const line = new THREE.Line3(points[i - 1], points[i]);
        const target: THREE.Vector3 = new THREE.Vector3();
        const distanceCurvePoint = line
          .closestPointToPoint(curvePoint, true, target)
          .distanceTo(curvePoint);
        if (
          distanceCurvePoint < maxDistance &&
          pointIndex < this.activeCurve.curve.points.length - 1
        ) {
          pointIndex++;
        }
        const distanceSearchPoint = line
          .closestPointToPoint(searchPoint, true, target)
          .distanceTo(searchPoint);
        if (distanceSearchPoint < maxDistance) return pointIndex;
      }
    }
    return -1;
  }

  snapPointCalculationIsRunning = false;
  onPointerMove(event: MouseEvent): void {
    if (!this.canEditCurve()) return;
    if (!this.snapPointCalculationIsRunning) {
      this.snapPointCalculationIsRunning = true;
      this.calculateSnapPoint(event).then(() => {
        this.snapPointCalculationIsRunning = false;
      });
    }
  }

  onPointerUp(event: MouseEvent): void {
    if (!this.canEditCurve()) return;
    this.calculateSnapPoint(event);
    if (this.selectionList.getSelectedObject())
      this.lastUpdateTime = Date.now();
    if (this.curvePointsChanged && this.historyList && this.activeCurve) {
      const newPoints = this.activeCurve.pointList.map((point) =>
        point.position.clone()
      );
      const oldPoints = this.oldCurvePoints.map((point) => point.clone());
      const activeCurve = this.activeCurve;
      const targetId = (this.activeCurve.target as any).apiData.uuid;
      this.historyList.add(
        targetId,
        HistoryOperationType.curvePoints,
        async () => {
          this.undoRedoCurvePoints(activeCurve, oldPoints);
        },
        async () => {
          this.undoRedoCurvePoints(activeCurve, newPoints);
        }
      );
    }
  }

  async calculateSnapPoint(event: MouseEvent): Promise<void> {
    if (this.dragObject && this.dragCurve) {
      const selection = this.dragCurve.target;
      if (selection) {
        this.intersectionPoint = this.selectionList.checkIntersection(
          event,
          false,
          selection
        );
        if (this.dragObject && this.intersectionPoint) {
          const position = this.dragObject.parent
            ? this.dragObject.parent.worldToLocal(
                selection.localToWorld(this.intersectionPoint)
              )
            : this.intersectionPoint;
          this.dragObject.position.copy(position);
          this.dragMesh(this.dragObject);
        }
      }
    }
  }
  //#endregion init

  //#region add
  getSceneBox(): THREE.Box3 {
    return THREEBoundingBox.getBoundingBox(this.modelValue.getMeshList());
  }

  getBoxSize(bbox: THREE.Box3): THREE.Vector3 {
    const measure = new THREE.Vector3(0, 0, 0);
    bbox.getSize(measure);
    return measure;
  }

  getSceneSize(): THREE.Vector3 {
    const bbox = this.getSceneBox();
    return this.getBoxSize(bbox);
  }

  getBoxCenter(bbox: THREE.Box3): THREE.Vector3 {
    const center = new THREE.Vector3(0, 0, 0);
    bbox.getCenter(center);
    return center;
  }

  async addPlaneCurve(): Promise<CurveDescription | null> {
    const bbox = this.getSceneBox();
    const measure = this.getBoxSize(bbox);
    this.ctrl_pt_geom = new THREE.SphereGeometry(measure.x / 2);
    const center = this.getBoxCenter(bbox);
    const geometry = new THREE.PlaneGeometry(measure.x * 100, measure.y * 100);
    const alpha = 30;
    const material = new THREE.MeshBasicMaterial({
      color: 0x333333,
      side: THREE.DoubleSide,
      opacity: alpha / 100.0,
      transparent: true,
    });

    // Create the final object to add to the scene
    const plane = this.modelValue.addObject(
      `${SpecialObjectPrefix.plane}${this.curveData.name}`,
      THREEEnum.ObjectType.Mesh,
      geometry,
      material,
      new THREE.Vector3(center.x, center.y, center.z + measure.z * 55),
      new THREE.Euler(0, 0, 0),
      new THREE.Vector3(1, 1, 1),
      this.getItemCategory()
    );
    plane.opacity = alpha;
    return await this.addCurve(plane, plane);
  }

  async addMeshCurve(): Promise<CurveDescription | null> {
    const selection = this.selectionList.getSelectedObject();
    if (selection) {
      const bbox = THREEBoundingBox.getBoundingBox([selection]);
      const measure = new THREE.Vector3(0, 0, 0);
      bbox.getSize(measure);
      this.ctrl_pt_geom = new THREE.SphereGeometry(measure.x / 100);
      return await this.addCurve(selection, this.getItemCategory(), selection);
    }
    return null;
  }

  private getItemCategory(): MeshTreeDataItem {
    const curveCategory = (this as any).$t(
      `enum.curve-category.${this.curveData.category}`
    );
    let itemCategory = this.modelValue.getItemByName(curveCategory);
    if (!itemCategory) {
      itemCategory = this.modelValue.addCategory(
        curveCategory,
        (this as any).$t('enum.upload-category.curve')
      );
    }
    return itemCategory;
  }

  submitCurve(event: Event | null = null): void {
    if (event) event.preventDefault();
    const dataForm = this.$refs.dataForm as typeof ElForm;
    dataForm?.validate(async (valid) => {
      if (valid) {
        const selection = this.selectionList.getSelectedObject();
        if (selection) {
          await this.addMeshCurve();
        } else {
          await this.addPlaneCurve();
        }
      }
    });
  }

  async addCurve(
    target: THREE.Object3D | MeshTreeDataItem | null,
    treeParent: MeshTreeDataItem,
    parent: THREE.Object3D | null = null
  ): Promise<CurveDescription | null> {
    const awaitTimeout = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));

    const curvePoints: THREE.Vector3[] = [];
    curvePoints.length = 0;
    const curve = new THREE.CatmullRomCurve3(
      curvePoints,
      this.curveData.closed,
      this.curveData.type
    );
    const points: THREE.Vector3[] =
      curvePoints.length > 1 ? curve.getPoints(minCurvePoints) : [];
    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    const material = new THREE.LineBasicMaterial({ color: 0xff0000 });

    // Create the final object to add to the scene
    const group = this.modelValue.addObject(
      `${SpecialObjectPrefix.curve}${this.curveData.name}`,
      THREEEnum.ObjectType.Line,
      geometry,
      material,
      new THREE.Vector3(0, 0, 0),
      new THREE.Euler(0, 0, 0),
      new THREE.Vector3(1, 1, 1),
      treeParent
    );
    //this.modelValue.updateDB(selectionItem.uuid);
    this.modelValue.updateStructure();
    let curveDescription: CurveDescription | null = null;
    await awaitTimeout(500).then(() => {
      const list = this.displayCurvePoints(group.mesh, curvePoints);
      this.modelValue.selectItem(group, true, true);
      if (target) {
        const curveResult = {
          info: {
            curveId: this.curveData.curveId,
            category: this.curveData.category,
            name: this.curveData.name,
            closed: this.curveData.closed,
            type: this.curveData.type,
          },
          target: target instanceof MeshTreeDataItem ? target.mesh : target,
          mesh: group.mesh,
          curve: curve,
          geometry: geometry,
          curvePoints: curvePoints,
          pointList: list,
          length: 0,
          pointCount: minCurvePoints,
        };
        this.curveList.push(curveResult);
        curveDescription = curveResult;
        this.activeCurve = curveResult;
      }
      if (parent) parent.add(group.mesh);
    });
    this.setCurveTool(CurveTool.add);

    const popoverAddCurve = this.$refs.popoverAddCurve as any;
    if (popoverAddCurve) popoverAddCurve.hide();
    return curveDescription;
  }
  //#endregion add

  //#region drag & drop
  activateDrag(): void {
    if (this.dragControls) this.dragControls.activate();
  }

  deactivateDrag(): void {
    if (this.dragControls) this.dragControls.deactivate();
  }

  setupDrag(): void {
    if (this.troisRenderer && this.troisRenderer.camera) {
      this.dragControls = new DragControls(
        [],
        this.troisRenderer.camera,
        this.troisRenderer.renderer.domElement
      );
    }

    this.dragControls.removeEventListener('drag', this.drag);
    this.dragControls.removeEventListener('dragstart', this.dragStart);
    this.dragControls.removeEventListener('dragend', this.dragEnd);

    this.dragControls.addEventListener('drag', this.drag);
    this.dragControls.addEventListener('dragstart', this.dragStart);
    this.dragControls.addEventListener('dragend', this.dragEnd);
  }

  addToDrag(objects: THREE.Object3D[]): void {
    const dragObjects = this.dragControls.getObjects();
    for (const object of objects) {
      const index = dragObjects.findIndex((item) => item.uuid === object.uuid);
      if (index === -1) dragObjects.push(object);
    }
  }

  removeFromDrag(objects: THREE.Object3D[]): void {
    const dragObjects = this.dragControls.getObjects();
    for (const object of objects) {
      const index = dragObjects.findIndex((item) => item.uuid === object.uuid);
      if (index > -1) dragObjects.splice(index, 1);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  dragStart(event: any): void {
    if (this.troisRenderer && this.troisRenderer.three.cameraCtrl)
      this.troisRenderer.three.cameraCtrl.enabled = false;

    this.dragObject = event.object;
    this.dragCurve = this.getCurveForMeshPoint(event.object);
  }

  dragEnd(): void {
    if (this.troisRenderer && this.troisRenderer.three.cameraCtrl)
      this.troisRenderer.three.cameraCtrl.enabled = true;

    this.dragObject = null;
    this.dragCurve = null;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  drag(event: any): void {
    this.dragMesh(event.object);
    this.curvePointsChanged = true;
  }

  dragMesh(meshPoint: THREE.Object3D): void {
    for (const curve of this.curveList) {
      let useThisCurve = false;
      for (let i = 0; i < curve.pointList.length; i++) {
        const point = curve.pointList[i];
        if (point.uuid === meshPoint.uuid) {
          curve.curve.points[i] = point.position;
          useThisCurve = true;
        }
      }
      if (useThisCurve) {
        CurveTools.displayCurve(curve);
      }
    }
  }
  //#endregion drag & drop

  //#region history
  undoRedoCurvePoints(curve: CurveDescription, points: THREE.Vector3[]): void {
    for (let i = points.length; i < curve.pointList.length; i++) {
      const deletePoint = curve.pointList[i];
      this.removeFromDrag([deletePoint]);
      const line = deletePoint.parent;
      if (line) {
        const linePointIndex = line.children.findIndex(
          (item) => item.uuid === deletePoint.uuid
        );
        if (linePointIndex > -1) {
          line.children.splice(linePointIndex, 1);
        }
      }
    }

    curve.curve.points.length = points.length;
    curve.pointList.length = points.length;
    for (let i = 0; i < points.length; i++) {
      curve.curve.points[i] = points[i].clone();
      if (curve.pointList[i])
        curve.pointList[i].position.copy(points[i].clone());
      else {
        const list = this.displayCurvePoints(curve.mesh, [points[i].clone()]);
        curve.pointList[i] = list[0];
      }
    }

    CurveTools.displayCurve(curve);
  }
  //#endregion history

  //#region display curve
  displayCurvePoints(
    curveGroup: THREE.Object3D,
    curvePoints: THREE.Vector3[]
  ): THREE.Object3D[] {
    const pointList: THREE.Object3D[] = [];
    for (const point of curvePoints) {
      const ctrl_pt_mesh = new THREE.Mesh(
        this.ctrl_pt_geom,
        this.ctrl_pt_material
      );
      ctrl_pt_mesh.position.copy(point);
      (ctrl_pt_mesh.material as any).ambient = ctrl_pt_mesh.material.color;
      ctrl_pt_mesh.name = SpecialObjectName.CurvePoint;
      curveGroup.add(ctrl_pt_mesh);
      pointList.push(ctrl_pt_mesh);
    }
    this.addToDrag(pointList);
    return pointList;
  }

  private static displayCurve(curve: CurveDescription): void {
    if (curve.curvePoints.length > 1) {
      curve.curve.updateArcLengths();
      const curveLength = Math.ceil(curve.curve.getLength());
      curve.length = curveLength;
      curve.pointCount =
        curveLength < minCurvePoints ? minCurvePoints : curveLength;
    }
    const points =
      curve.curve.points.length > 1
        ? curve.curve.getPoints(curve.pointCount)
        : [];
    curve.geometry.setFromPoints(points);
  }

  private getCurveForMeshPoint(
    meshPoint: THREE.Object3D
  ): CurveDescription | null {
    for (const curve of this.curveList) {
      for (let i = 0; i < curve.pointList.length; i++) {
        const point = curve.pointList[i];
        if (point.uuid === meshPoint.uuid) {
          return curve;
        }
      }
    }
    return null;
  }

  private getCurveForLine(line: THREE.Object3D): CurveDescription | null {
    for (const curve of this.curveList) {
      if (curve.mesh.uuid == line.uuid) return curve;
    }
    for (const child of line.children) {
      const childCurve = this.getCurveForLine(child);
      if (childCurve) return childCurve;
    }
    return null;
  }
  //#endregion display curve

  //#region transform curve
  curvePlaneSelected(): boolean {
    const selection = this.selectionList.getSelectedObject();
    return this.isCurvePlane(selection);
  }

  isCurvePlane(object: THREE.Object3D | null | undefined): boolean {
    const apiData = (object as any)?.apiData;
    return (
      !!object &&
      !!apiData &&
      apiData.name.startsWith(SpecialObjectPrefix.plane)
    );
  }

  @Watch('canCurve', { immediate: true })
  onCanCurveChanged(): void {
    this.selectedObjectChanged();
    this.onTransformToolsChanged();
    const curveItem = this.modelValue.getItemByName(
      (this as any).$t('enum.upload-category.curve')
    );
    if (curveItem) {
      curveItem.active = this.canCurve;
      this.modelValue.updateStructure();
    }
    this.activatedCategoryTypes();
  }

  @Watch('allowedCurveCategories', { immediate: true, deep: true })
  activatedCategoryTypes(): void {
    if (this.allowedCurveCategories) {
      this.curveData.category = this.getDefaultCategory();
      for (const category of Object.values(CurveCategory)) {
        const isActive =
          this.allowedCurveCategories.includes(category) && this.canCurve;
        const categoryItem = this.modelValue.getItemByName(
          (this as any).$t(`enum.curve-category.${category}`)
        );
        if (categoryItem) {
          categoryItem.active = isActive;
        }
      }
      const curveItem = this.modelValue.getItemByName(
        (this as any).$t('enum.upload-category.curve')
      );
      if (curveItem) {
        curveItem.updateActiveChildren();
        this.modelValue.updateStructure();
      }
    }
  }

  getDefaultCategory(): CurveCategory {
    return this.allowedCurveCategories &&
      this.allowedCurveCategories.length > 0 &&
      !this.allowedCurveCategories.includes(CurveCategory.ZIP)
      ? this.allowedCurveCategories[0]
      : CurveCategory.ZIP;
  }

  isCurvePlaneSelected = false;
  selectedObjectChanged(): void {
    if (this.canCurve) {
      const selection = this.selectionList.getSelectedObject();
      if (selection) {
        this.activeCurve = this.getCurveForLine(selection);
      } else {
        this.activeCurve = null;
      }
      this.isCurvePlaneSelected = this.curvePlaneSelected();
      const measure = this.getSceneSize();
      let pointRadius = measure.x / (this.isCurvePlaneSelected ? 2 : 100);
      const isLine = selection ? (selection as any).isLine : false;
      if (isLine) {
        const isPlaneLineSelected = this.isCurvePlane(selection?.parent);
        if (isPlaneLineSelected) pointRadius = measure.x / 2;
      }
      this.ctrl_pt_geom = new THREE.SphereGeometry(pointRadius);
      setTimeout(() => {
        if (this.transformTools) {
          this.transformTools.setTransformControlsForTarget(
            this.isCurvePlaneSelected
              ? this.selectionList.getSelectedObject()
              : null
          );
        }
      }, 100);
    }
  }

  @Watch('transformTools.isModifyTypeNone', { immediate: true })
  onTransformToolsChanged(): void {
    if (this.canEditCurve()) {
      this.activateDrag();
      this.isModifyTypeMove = this.isCurveTool(CurveTool.move);
      this.isModifyTypeAdd = this.isCurveTool(CurveTool.add);
      this.isModifyTypeRemove = this.isCurveTool(CurveTool.remove);
    } else {
      this.deactivateDrag();
      this.isModifyTypeMove = false;
      this.isModifyTypeAdd = false;
      this.isModifyTypeRemove = false;
    }
  }

  saveTransform(): void {
    //
  }
  //#endregion transform curve

  //#region import / export
  exportCurves(): CurveExportData[] {
    return this.curveList.map((data) => {
      return {
        curveId: data.info.curveId,
        category: data.info.category,
        name: data.info.name,
        closed: data.info.closed,
        type: data.info.type,
        meshId: data.target.name.startsWith(SpecialObjectPrefix.plane)
          ? null
          : (data.target as any).apiData.id,
        position: data.target.position.clone(),
        rotation: data.target.rotation.clone(),
        scale: data.target.scale.clone(),
        curvePoints: data.curvePoints,
      };
    });
  }

  async importCurves(data: CurveExportData[]): Promise<void> {
    for (const item of data) {
      this.curveData.curveId = item.curveId;
      this.curveData.category = item.category;
      this.curveData.name = item.name;
      this.curveData.closed = item.closed;
      this.curveData.type = item.type;
      let curveDescription: CurveDescription | null = null;
      if (item.meshId) {
        const selection = this.troisRenderer.scene?.children.find(
          (sceneItem) => {
            if ((sceneItem as any).apiData)
              return (sceneItem as any).apiData.id === item.meshId;
            return false;
          }
        );
        if (selection)
          curveDescription = await this.addCurve(
            selection,
            this.getItemCategory(),
            selection
          );
      } else {
        curveDescription = await this.addPlaneCurve();
      }
      if (curveDescription) {
        if (!item.meshId) {
          curveDescription.target.position.copy(item.position.clone());
          curveDescription.target.rotation.copy(item.rotation.clone());
          curveDescription.target.scale.copy(item.scale.clone());
        }
        curveDescription.curve.points.push(...item.curvePoints);
        const list = this.displayCurvePoints(
          curveDescription.mesh,
          item.curvePoints
        );
        curveDescription.pointList.push(...list);
        CurveTools.displayCurve(curveDescription);
      }
    }
    this.onCanCurveChanged();
  }
  //#endregion import / export
}

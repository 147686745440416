import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bf8e8706"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "footer",
  class: "row align-items-center"
}
const _hoisted_2 = { class: "col mb-0 ms-5 text-start" }
const _hoisted_3 = { class: "col me-5 text-end" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "ms-5 text-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.isEditor)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('components.custom-footer.companyInfo')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("a", {
            class: "link-underline link-underline-opacity-0 text-white",
            href: _ctx.privacyUri
          }, _toDisplayString(_ctx.$t('components.custom-footer.privacy')), 9, _hoisted_4),
          _createTextVNode(" | "),
          _createElementVNode("a", {
            class: "link-underline link-underline-opacity-0 text-white",
            href: _ctx.copyrightUri
          }, _toDisplayString(_ctx.$t('components.custom-footer.copyright')), 9, _hoisted_5)
        ]),
        _createElementVNode("p", _hoisted_6, "(" + _toDisplayString(_ctx.version) + ")", 1)
      ]))
    : _createCommentVNode("", true)
}
import { Body_Attribute } from '@/types/api/Patient/BodyAttribute';
import { queryWithVariables } from './api';
import { Customer } from '@/types/api/User/Customer';
import { File } from '@/types/api/Utility/File';

export const getBodyAttributesByUserId = async (userId: number): Promise<Body_Attribute[]> => {
  const getBodyAttributesResult = await queryWithVariables(
    `
        query ($userId: Long!) {
          bodyAttributesByUserId (userId: $userId) {
            characteristicId,
            date,
            scanFile {
              fileId,
              name,
              type
            },
            skeleton {
              fileId,
              name,
              type
            },
            scaleFactor,
            side,
            roi {
              roiId,
              length,
              width,
              depth
            },
            bodyPart {
              bodyPartId,
              name
            }
          }
        }
      `,
    {
      userId: userId,
    }
  );

  const copyBodyAttributes: Body_Attribute[] = [];
  const bodyAttributes = getBodyAttributesResult.data.bodyAttributesByUserId as Body_Attribute[];
  for (const bodyAttribute of bodyAttributes) {
    copyBodyAttributes.push(new Body_Attribute().deepCopy(bodyAttribute));
  }

  return copyBodyAttributes;
}

export const getScanById = async (fileId: number): Promise<File> => {
  const getScanResult = await queryWithVariables(
    `
        query ($fileId: Long!) {
          scanById (fileId: $fileId) {
            content
          }
        }
      `,
    {
      fileId: fileId,
    }
  );

  return getScanResult.data.scanById;
};

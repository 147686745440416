import { createApp, ref } from 'vue';
import VueCookies from 'vue3-cookies';
import i18n from '@/i18n';
import App from '@/App.vue';
import router from './router';
import mitt, { Emitter, EventType } from 'mitt';
import { TroisJSVuePlugin } from 'troisjs';

import ElementPlus from 'element-plus';
import '@/assets/styles/global.scss';
import '@/assets/styles/element-plus.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
// internal icons
import { fas } from '@fortawesome/pro-solid-svg-icons';
//import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { fac } from '@/assets/icons/fac';
import { IconPack } from '@fortawesome/fontawesome-common-types';

import '@/assets/styles/bootstrap.scss';
import { KEY_CURRENT_NAME, KEY_CURRENT_PRICE, KEY_CURRENT_STEP, KEY_IS_EDITOR, KEY_STEP_TEXT } from './utils/keys';

library.add(fas as IconPack);
library.add(far);
library.add(fac);

/* eslint-disable @typescript-eslint/no-explicit-any*/

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    eventBus: Emitter<Record<EventType, unknown>>;
  }
}

const eventBus = mitt();
const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(router);
app.use(i18n as any);
app.use(VueCookies as any, {
  expireTimes: '30d',
  secure: true,
  sameSite: 'Strict', // "Lax"
});
app.config.globalProperties.eventBus = eventBus;
app.use(ElementPlus);
app.use(TroisJSVuePlugin);

// Global provides
app.provide(KEY_IS_EDITOR, ref(false));
app.provide(KEY_STEP_TEXT, ref("firstStepDescription"));
app.provide(KEY_CURRENT_NAME, ref("S-Flex Cover"));
app.provide(KEY_CURRENT_PRICE, ref("235"));
app.provide(KEY_CURRENT_STEP, ref(1));

app.mount('#app');

export default app;


import { ref } from "vue";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ProductQuality from "../three/ProductQuality.vue";
import OrientationGizmo from "../three/OrientationGizmo.vue";
import { WorkflowModel } from "@/types/ui/WorkflowModel";
import { Views } from "@/types/enum/three";
import * as THREEEnum from '@/types/enum/three';
import { RendererPublicInterface } from "troisjs";
import MeshEditor from "../three/MeshEditor.vue";

@Options({
  components: {
    ProductQuality,
    OrientationGizmo
  },
  emits: ['productQualityChanged', 'toggleOpacity'],
})

export default class SidebarTools extends Vue {
  @Prop({default: true}) canResolution!: boolean;
  @Prop({ default: null }) workflowModel!: WorkflowModel;
  @Prop({ default: 3 }) selectedProductQualityIndex!: number;
  @Prop({ default: null }) meshEditor!: MeshEditor;

  showSendMenu = false;
  showSettingsMenu = false;
  isXrActive = false;

  Views = Views;
  view: string = THREEEnum.Views.custom;

  orientationGizmo: OrientationGizmo | null = null;
  troisRenderer: any | null = null;

  mounted(): void {
    if (navigator.xr) {
      navigator.xr.isSessionSupported('immersive-ar').then((supported) => {
        this.isXrActive = supported;
      });
    }
  }

  productQualityChanged(selectedProductQuality: ProductQuality) {
    this.$emit('productQualityChanged', selectedProductQuality);
  }
}

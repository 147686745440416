import { 
  apiExecutePost,
  mutateWithVariables,
  query,
  queryWithVariables
} from '@/services/api/api';

/* eslint-disable @typescript-eslint/no-explicit-any*/

export interface OrderRequestData {
  product: string;
  colorList: (string | null)[];
  embossingList: (string | null)[];
  embossingColorList: (string | null)[];
  wtransferList: (string | null)[];
  technician: string;
  company: string;
  commissionNo: string;
  mail: string;
  orderMail: string;
  coverModel: string;
  prothesisType: string;
  bodySide: string;
  kneeJoint: string;
  foot: string;
  zip: string;
  heightWithoutDeduction: number;
  heightDeduction: number;
  heightWithDeduction: number;
  depth: number;
  calfCircumferenceWithoutDeduction: number;
  calfCircumferenceDeduction: number;
  calfCircumferenceWithDeduction: number;
  ankleCircumference: number;
  imageFiles: string[];
  scanFiles: string[];
  designFiles: string[];
  remarks: string;
  price: number;
  balconyType: string;
  mountingType: string;
  paneelType: string;
  isPrivacyAccepted: boolean;
  privacy: string;
}

export const postOrder = async (
  data: Partial<OrderRequestData>
): Promise<any> => {
  return await apiExecutePost<any>(
    '',
    data,
    process.env.VUE_APP_BACKEND_ORDER_URL
  );
};

export const createPdfPartList = async (modelId: number, domainId: number): Promise<Uint8Array> => {
  const result = await mutateWithVariables(
    `
        mutation ($modelId: Long!, $domainId: Long!) {
          createPdfPartList(
            modelId: $modelId
            domainId: $domainId
          )
        }
      `,
    {
      modelId: modelId,
      domainId: domainId
    }
  );
  return result.data.createPdfPartList;
};

export const createPdfOffer = async (orderData: OrderRequestData, modelId: number, domainId: number): Promise<Uint8Array> => {
  const result = await mutateWithVariables(
    `
        mutation ($orderData: OrderDataInput!, $modelId: Long!, $domainId: Long!) {
          createPdfOffer(
            orderData: $orderData
            modelId: $modelId
            domainId: $domainId
          )
        }
      `,
    {
      orderData: orderData,
      modelId: modelId,
      domainId: domainId
    }
  );
  return result.data.createPdfOffer;
};

export const sendOrderAsync = async (orderData: OrderRequestData, modelId: number, domainId: number): Promise<boolean> => {
  const result = await mutateWithVariables(
    `
        mutation ($orderData: OrderDataInput!, $modelId: Long!, $domainId: Long!) {
          sendOrder(
            orderData: $orderData
            modelId: $modelId
            domainId: $domainId
          )
        }
      `,
    {
      orderData: orderData,
      modelId: modelId,
      domainId: domainId
    }
  );
  return result.data;
};


import Auth from '@/services/api/auth';
import { User } from '@/types/api/User/User';
import { Options, Vue } from 'vue-class-component';
import CustomHeader from "@/components/CustomHeader.vue";
import CustomFooter from "@/components/CustomFooter.vue";
import ProductCard from '@/components/ProductCard.vue';
import * as modelService from '@/services/api/modelService';
import { Model2 } from '@/types/api/Model/Model';
import { Product_Type } from '@/types/api/Model/ProductType';
import { Product } from '@/types/api/Model/Product';
import PreviewRendererImg from '@/components/three/PreviewRendererImg.vue';

@Options({
  components: {
    ProductCard,
    CustomFooter,
    CustomHeader,
    PreviewRendererImg
  },
})

export default class CustomizeOverview extends Vue {
  authentication = Auth.getInstance();
  user: User | null = null;
  modelList: Model2[] = [];
  productsLoading = false;
  supplierUrl: string | null = null;
  isHomepage = false;

  mounted(): void {
    this.productsLoading = true;
    this.supplierUrl = this.$route.params.supplier == undefined ? null : this.$route.params.supplier.toString();
    this.isHomepage = this.supplierUrl != null;
    this.authentication.handleAuthentication(this.supplierUrl).then(() => {
      //get user information
      this.user = this.authentication.user;
      const domain = this.authentication.domain;
      if (this.user) {
        modelService.getModelsByUserId(this.user.userId, null)
          .then((models) => {
            if (models) {
              const mList = models.filter((item) => !item.basedOn);
              this.modelList = mList;
            } else 
              this.modelList = [];
          })
          .finally(() => {
            this.productsLoading = false;
          });
      } else if (domain) {
        modelService.getModelsByUrlSuffix(this.supplierUrl!, null)
          .then((models) => {
            if (models) {
              const mList = models.filter((item) => !item.basedOn);
              this.modelList = mList;
              const productTypes = this.getProductTypes();
            } else {
              this.modelList = [];
            }
          })
          .finally(() => {
            this.productsLoading = false;
          });
      } else {
        this.$router.replace(`/page/not-found`);
      }
    });
  }

  getProductTypes(): Product[] {
    const productTypeList: Product[] = [];
    for(const model of this.modelList) {
      if (productTypeList.findIndex(pt => pt.type.productTypeId == model.product.type.productTypeId) === -1) {
        productTypeList.push(model.product);
      }
    }
    return productTypeList;
  }
}

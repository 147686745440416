
import {MeshTreeData, MeshTreeDataGroupItem, MeshTreeDataItem} from "@/types/ui/MeshTreeData";
import ButtonCard from "@/components/ButtonCard.vue";
import PreviewRendererImg from "@/components/three/PreviewRendererImg.vue";
import { WorkflowModel } from "@/types/ui/WorkflowModel";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ProductQuality from "./three/ProductQuality.vue";
import { waitFor } from "@/utils/three/webGlContext";


@Options({
  components: {
    PreviewRendererImg,
    ButtonCard
  }
})

export default class PartSelection extends Vue {
  @Prop() meshTreeData!: MeshTreeData;
  @Prop() productTypeString!: string;

  meshCountItemList: MeshTreeDataGroupItem[] = [];

  mounted(): void {
    this.meshTreeData.getPartItemList().then((list) => {
      this.meshCountItemList = list;
    })
  }
}


import * as THREE from 'three';
import { FileType3D, UploadCategory } from '@/types/enum/upload';
import { getMeshExport } from '@/utils/three/importExport';
import { v4 as uuidv4 } from 'uuid';
import * as THREEEnum from '@/types/enum/three';
import { ProjectionSidebarData } from '@/components/Tools/EmbossingSidebar.vue';
import * as modelService from '@/services/api/modelService';
import * as componentService from '@/services/api/componentService';
import { ProductQuality } from '@/types/enum/template';
import { Engraving } from '@/types/api/Model/Attributes/Engraving';
import { Color } from '@/types/api/Model/Attributes/Color';
import { Print } from '@/types/api/Model/Attributes/Print';
import { Model2 } from '@/types/api/Model/Model';
import { Size } from '@/types/api/Model/Attributes/Size';

/* eslint-disable @typescript-eslint/no-explicit-any*/

export interface BoneData {
  name: string;
  position: THREE.Vector3;
}

export interface FFDExportData {
  spanCounts: number[];
  gridPositionList: THREE.Vector3[];
}

export interface EmbossingExportData {
  projectionType: THREEEnum.ProjectionType;
  position: THREE.Vector3 | null;
  rotation: THREE.Euler;
  scale: THREE.Vector3;
  projection: ProjectionSidebarData;
}

export interface TemplateData {
  meshes: modelService.MeshExportData[];
  curves: modelService.CurveExportData[];
  cameras: modelService.CameraExportData[];
}

const localTemplateDB: { [key: number]: TemplateData } = {};

export const getTemplate = async (
  templateId: number,
  productQuality: ProductQuality = ProductQuality.low,
  abortController: AbortController
): Promise<Model2> => {
  return await modelService.getModel2(
    templateId,
    productQuality,
    abortController
  );
};

export const getData = async (
  templateId: number,
  productQuality: ProductQuality = ProductQuality.low
): Promise<TemplateData> => {
  if (!(templateId in localTemplateDB)) {
    localTemplateDB[templateId] = {
      meshes: [],
      curves: [],
      cameras: [],
    };
  }

  const result = await modelService.getModel(templateId, productQuality);
  for (const component of result.components) {
    const meshIndex = localTemplateDB[templateId].meshes.findIndex(
      (item) => item.componentId === component.componentId
    );
    if (meshIndex === -1) {
      localTemplateDB[templateId].meshes.push(
        modelService.parseToMeshExportData(component, component.resolutions[0])
      );
    } else {
      localTemplateDB[templateId].meshes[meshIndex] =
        modelService.parseToMeshExportData(component, component.resolutions[0]);
    }
    for (const curve of component.curves) {
      const curveIndex = localTemplateDB[templateId].curves.findIndex(
        (item) => item.curveId === curve.curveId
      );
      if (curveIndex === -1) {
        localTemplateDB[templateId].curves.push(
          modelService.parseToCurveExportData(curve, component)
        );
      } else {
        localTemplateDB[templateId].curves[curveIndex] =
          modelService.parseToCurveExportData(curve, component);
      }
    }
  }
  for (const curve of result.curves) {
    const curveIndex = localTemplateDB[templateId].curves.findIndex(
      (item) => item.curveId === curve.curveId
    );
    if (curveIndex === -1) {
      localTemplateDB[templateId].curves.push(
        modelService.parseToCurveExportData(curve)
      );
    } else {
      localTemplateDB[templateId].curves[curveIndex] =
        modelService.parseToCurveExportData(curve);
    }
  }
  for (const camera of result.cameras) {
    const cameraIndex = localTemplateDB[templateId].cameras.findIndex(
      (item) => item.cameraId === camera.cameraId
    );
    if (cameraIndex === -1) {
      localTemplateDB[templateId].cameras.push(
        modelService.parseToCameraExportData(camera)
      );
    } else {
      localTemplateDB[templateId].cameras[cameraIndex] =
        modelService.parseToCameraExportData(camera);
    }
  }
  return localTemplateDB[templateId];
};

export const getMeshData = async (
  templateId: number,
  productQuality: ProductQuality = ProductQuality.low
): Promise<modelService.MeshExportData[]> => {
  const data = await getData(templateId, productQuality);
  return data.meshes;
};

export const getTemplateData = async (
  templateId: number,
  productQuality: ProductQuality = ProductQuality.low,
  abortController: AbortController
): Promise<Model2> => {
  const template = await getTemplate(
    templateId,
    productQuality,
    abortController
  );
  return template;
};

export const getEmbossingData = async (
  componentId: number
): Promise<Engraving> => {
  const engraving = await modelService.getEngraving(componentId);
  return engraving;
};

export const getTextureData = async (componentId: number): Promise<Print> => {
  const texture = await modelService.getTexture(componentId);
  return texture;
};

export const getColorData = async (componentId: number): Promise<Color> => {
  const color = await modelService.getColor(componentId);
  return color;
};

export const getSizeData = async (componentId: number): Promise<Size> => {
  const size = await modelService.getSize(componentId);
  return size;
};

export const getCurveData = async (
  templateId: number
): Promise<modelService.CurveExportData[]> => {
  const data = await getData(templateId);
  return data.curves;
};

export const getCameraData = async (
  templateId: number
): Promise<modelService.CameraExportData[]> => {
  const data = await getData(templateId);
  return data.cameras;
};

export const convertMesh = async (
  mesh: THREE.Object3D
): Promise<{ fileType: FileType3D; base64: string | undefined }> => {
  const fileType = FileType3D.OBJ;
  const exportMesh = mesh.clone().clear();
  exportMesh.position.set(0, 0, 0);
  exportMesh.rotation.set(0, 0, 0);
  exportMesh.scale.set(1, 1, 1);
  exportMesh.updateMatrixWorld();
  const base64 = await getMeshExport(exportMesh, fileType);
  return { fileType, base64 };
};

export const addMeshData = async (
  templateId: number | null,
  meshCategory: UploadCategory,
  meshName: string,
  mesh: THREE.Object3D,
  bones: THREE.Bone[],
  parentId: number | null = null,
  uploadToDB = true
): Promise<{ id: string; templateId: number | null }> => {
  const id = (mesh as any).apiData ? (mesh as any).apiData.id : uuidv4();
  let { fileType, base64 } = await convertMesh(mesh);
  if (!base64) {
    fileType = FileType3D.NONE;
    base64 = '';
  }
  if (uploadToDB) {
    if (templateId) {
      await componentService.addComponents(
        templateId,
        id,
        meshName,
        base64,
        meshName,
        fileType.toString(),
        mesh.position,
        mesh.rotation,
        mesh.scale,
        meshCategory,
        1,
        null,
        '.jpg',
        bones,
        parentId
      );
    } else {
      templateId = await modelService.upload(
        id,
        meshName,
        base64,
        meshName,
        fileType.toString(),
        mesh.position,
        mesh.rotation,
        mesh.scale,
        meshCategory,
        1,
        null,
        '.jpg',
        bones,
        parentId
      );
    }
  }

  if (templateId) {
    getMeshData(templateId);
  }
  return { id: id, templateId: templateId };
};

export const updateMeshData = async (
  templateId: number,
  id: string,
  meshCategory: UploadCategory,
  meshName: string,
  mesh: THREE.Object3D,
  bones: THREE.Bone[],
  parentId: number | null = null,
  uploadToDB = true
): Promise<string> => {
  const data = await getMeshData(templateId);
  const dbIndex = data.findIndex((item) => item.uniqueKey === id);
  if (dbIndex > -1) {
    if (
      (mesh as any).isGroup &&
      mesh.children.length > 0 &&
      (mesh.children[0] as any).isMesh
    )
      mesh = mesh.children[0];

    let { fileType, base64 } = await convertMesh(mesh);
    if (!base64) {
      fileType = FileType3D.NONE;
      base64 = '';
    }
    if (uploadToDB) {
      componentService.updateComponent(
        templateId,
        id,
        meshName,
        base64,
        meshName,
        fileType.toString(),
        mesh.position,
        mesh.rotation,
        mesh.scale,
        meshCategory,
        1,
        null,
        '.jpg',
        bones,
        parentId
      );
    }
    if (templateId) {
      getMeshData(templateId);
    }
    return id;
  } else {
    return (
      await addMeshData(
        templateId,
        meshCategory,
        meshName,
        mesh,
        bones,
        parentId,
        uploadToDB
      )
    ).id;
  }
};

export const updateFfdData = async (
  templateId: number,
  id: string,
  ffd: FFDExportData | null = null
): Promise<void> => {
  const data = await getMeshData(templateId);
  const dbIndex = data.findIndex((item) => item.uniqueKey === id);
  if (dbIndex > -1) {
    data[dbIndex].ffd = ffd;
  }
};

export const updateEmbossingData = async (
  templateId: number,
  id: string,
  embossing: EmbossingExportData[]
): Promise<void> => {
  const data = await getMeshData(templateId);
  const dbIndex = data.findIndex((item) => item.uniqueKey === id);
  if (dbIndex > -1) {
    data[dbIndex].embossing = embossing;
  }
};

export const updateCurveData = async (
  templateId: number,
  curves: modelService.CurveExportData[]
): Promise<void> => {
  const data = await getData(templateId);
  data.curves = curves;
};

export const updateCameraData = async (
  templateId: number,
  camera: modelService.CameraExportData[]
): Promise<void> => {
  const data = await getData(templateId);
  data.cameras = camera;
};

export const deleteMeshData = async (
  templateId: number,
  id: string
): Promise<void> => {
  const data = await getMeshData(templateId);
  const dbIndex = data.findIndex((item) => item.uniqueKey === id);
  if (dbIndex > -1) {
    data.splice(dbIndex, 1);
  }
};

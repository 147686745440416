
import { Options, Vue } from 'vue-class-component';
import { ElForm, ElMessage, ElMessageBox } from 'element-plus';
import {
  UploadFile,
  UploadStatus,
} from 'element-plus/es/components/upload/src/upload';
import { cleanUpScene, ObjModel, StlModel } from '@/utils/three/importExport';
import MeshEditor from '@/components/three/MeshEditor.vue';
import { MeshTreeData } from '@/types/ui/MeshTreeData';
import { Prop, Watch } from 'vue-property-decorator';
import {
  FileType3D,
  FileTypeImage,
  getFileType,
  getImageFileType,
  isValidFileType,
  isValidImageFileType,
  UploadCategory,
  UploadProgress,
} from '@/types/enum/upload';
import { ModifyType, OrientationAxis } from '@/types/enum/three';
import draggable from 'vuedraggable';
import * as THREE from 'three';
import { EditorModeState } from '@/types/enum/editor';
import PreviewRenderer from '@/components/three/PreviewRenderer.vue';
import PreviewRendererImg from '@/components/three/PreviewRendererImg.vue';
import * as TemplateService from '@/services/api/templateService';
import { MeshExportData } from '@/services/api/modelService';
import { DefaultUndoRedo } from '@/types/ui/HistoryList';
import * as THREEEnum from '@/types/enum/three';
import { fileContentToBase64 } from '@/utils/file';

interface UploadData {
  name: string;
  url: string;
  uid: number;
  status?: UploadStatus;
}

interface LoadingData {
  referenceCategory: UploadCategory;
  referenceId: string;
  category: UploadCategory;
  title: string;
  fileType: FileType3D;
  url: string;
  base64: string;
}

interface ResultData {
  category: UploadCategory;
  title: string;
  mesh: THREE.Object3D;
  bones: THREE.Bone[];
  referenceId: string;
}

@Options({
  components: {
    PreviewRenderer,
    MeshEditor,
    ObjModel,
    StlModel,
    draggable,
    PreviewRendererImg,
  },
  emits: ['update:uploadProgress'],
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class MeshLoader extends Vue implements DefaultUndoRedo {
  @Prop({ default: UploadProgress.Select }) uploadProgress!: UploadProgress;
  @Prop({ default: null }) templateId!: number | null;
  @Prop({ default: false }) canSelect!: boolean;
  @Prop({ default: false }) canTakeSnapshot!: boolean;
  @Prop({ default: false }) canToggleOpacity!: boolean;
  selected = -1;
  selectedInput: MeshTreeData = new MeshTreeData(null, this);
  UploadProgress = UploadProgress;
  ModifyType = ModifyType;

  loadingData: LoadingData = {
    referenceCategory: UploadCategory.ReferenceDummy,
    referenceId: '',
    category: UploadCategory.Product,
    title: '',
    fileType: FileType3D.NONE,
    url: '',
    base64: '',
  };

  boneData: THREE.Bone[] = [];
  activeBoneIndex = 0;
  boneKey = 0;

  activeEditorMode: EditorModeState = EditorModeState.readonly;

  referenceData: MeshExportData[] = [];

  UploadCategory = UploadCategory;
  FileType = FileType3D;
  previousUploadProgress = UploadProgress.Select;
  OrientationAxis = OrientationAxis;
  getFileType = getFileType;
  getImageFileType = getImageFileType;

  mounted(): void {
    if (this.templateId) {
      TemplateService.getMeshData(this.templateId).then(
        (result) => (this.referenceData = result)
      );
    }

    this.addBone((this as any).$t('components.mesh-loader.pivotBone'));
    this.addBone((this as any).$t('components.mesh-loader.secondBone'));
    this.$nextTick(() => {
      this.activeBoneIndex = 0;
    });
  }

  get categoryReferences(): MeshExportData[] {
    const references = this.referenceData.filter(
      (item) => item.category === this.loadingData.referenceCategory
    );
    this.loadingData.referenceId = '';
    if (references.length > 0)
      this.loadingData.referenceId = references[0].uniqueKey;
    return references;
  }

  getResult(): ResultData | null {
    if (this.selectedInput.treeData.length > 0) {
      const treeData = this.selectedInput.treeData[0];
      return {
        category: this.loadingData.category,
        title: this.loadingData.title,
        mesh: treeData.mesh,
        bones: treeData.bones,
        referenceId: this.loadingData.referenceId,
      };
    }
    return null;
  }

  @Watch('templateId', { immediate: true })
  onTemplateIdChanged(): void {
    this.selectedInput.modelId = this.templateId;
    this.selectedInput.historyList.modelId = this.templateId;
    this.selectedInput.historyList.defaultUndoRedo = this;
  }

  @Watch('selectedInput.lastUpdateVisibility')
  async onSelectedInputVisibilityChanged(): Promise<void> {
    if (this.activeEditorMode === EditorModeState.bone) {
      if (this.selectedInput && this.selectedInput.treeData.length > 0) {
        this.activeBoneIndex = this.selectedInput.treeData[0].activeBoneIndex;
      }
    }
  }

  @Watch('loadingData.referenceId', { immediate: true })
  onReferenceIdChanged(): void {
    if (this.templateId && this.loadingData.referenceId) {
      const referenceItem = this.referenceData.find(
        (item) => item.uniqueKey === this.loadingData.referenceId
      );
      if (referenceItem) {
        this.boneData.length = 0;
        for (const boneData of referenceItem.bones) {
          const bone = this.addBone(boneData.name);
          bone.position.copy(boneData.position.clone());
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async dragDone(event: any): Promise<void> {
    this.boneKey += 1;
    if (this.activeBoneIndex === event.oldIndex) {
      this.activeBoneIndex = event.newIndex;
    } else if (
      this.activeBoneIndex < event.oldIndex &&
      this.activeBoneIndex >= event.newIndex
    ) {
      this.activeBoneIndex++;
    } else if (
      this.activeBoneIndex > event.oldIndex &&
      this.activeBoneIndex <= event.newIndex
    ) {
      this.activeBoneIndex--;
    }
    this.selectedInput.updateVisibility();
  }

  @Watch('activeBoneIndex')
  onActiveBoneIndexChanged(): void {
    if (this.selectedInput && this.selectedInput.treeData.length > 0) {
      this.selectedInput.treeData[0].activeBoneIndex = this.activeBoneIndex;
      this.selectedInput.updateVisibility();
    }
  }

  addBone(name: string | null = null): THREE.Bone {
    const defaultBoneDistance = 100;
    const bone = new THREE.Bone();
    bone.name = name ? name : (this.boneData.length + 1).toString();
    if (this.boneData.length === 0) {
      bone.position.set(0, 0, 0);
    } else {
      const to = this.boneData[this.boneData.length - 1].position.clone();
      const from =
        this.boneData.length === 1
          ? new THREE.Vector3(0, -1, 0)
          : this.boneData[this.boneData.length - 2].position.clone();
      const direction = to
        .clone()
        .sub(from)
        .normalize()
        .setLength(defaultBoneDistance);
      bone.position.copy(to.clone().add(direction));
    }
    this.boneData.push(bone);
    this.selectedInput.updateVisibility();
    this.$nextTick(() => {
      this.activeBoneIndex = this.boneData.length - 1;
    });
    return bone;
  }

  removeBone(index: number): void {
    ElMessageBox.confirm(
      (this as any).$t('confirm.delete.message'),
      (this as any).$t('confirm.delete.title'),
      {
        confirmButtonText: (this as any).$t('confirm.delete.ok'),
        cancelButtonText: (this as any).$t('confirm.delete.cancel'),
        type: 'warning',
      }
    ).then(() => {
      this.boneData.splice(index, 1);
      this.selectedInput.updateVisibility();
    });
  }

  isFileType(filename: string, filetype: FileType3D): boolean {
    return getFileType(filename) === filetype;
  }

  @Watch('uploadProgress', { immediate: true })
  onUploadProgressChanged(): void {
    if (
      this.previousUploadProgress === UploadProgress.Select &&
      this.uploadProgress !== this.previousUploadProgress
    ) {
      this.previousUploadProgress = this.uploadProgress;
      const dataForm = this.$refs.dataForm as typeof ElForm;
      dataForm?.validate(async (valid) => {
        if (!valid) {
          this.$emit('update:uploadProgress', UploadProgress.Select);
          this.previousUploadProgress = UploadProgress.Select;
        }
      });
    }

    switch (this.uploadProgress) {
      case UploadProgress.Select:
        this.activeEditorMode = EditorModeState.readonly;
        break;
      case UploadProgress.Orientation:
        this.activeEditorMode = EditorModeState.orientation;
        break;
      case UploadProgress.Pivot:
        this.activeEditorMode = EditorModeState.bone;
        break;
    }
  }

  takeSnapshot(): void {
    const meshEditor: any = this.$refs.meshEditor;
    const img = new Image();

    if (meshEditor.troisRenderer.scene && meshEditor.troisRenderer.camera) {
      meshEditor.troisRenderer.three.renderer.render(
        meshEditor.troisRenderer.scene,
        meshEditor.troisRenderer.camera
      );
    }

    img.src = meshEditor.troisRenderer.three.renderer.domElement.toDataURL();

    meshEditor.troisRenderer.three.renderer.clear();

    for(const item of this.selectedInput.treeData) {
      if (item.isSelected) {
        item.thumbnailUrl = img.src;
        item.thumbnail = item.name;
        item.thumbnailFiletype = FileTypeImage.JPG
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async uploadFile(res: any): Promise<boolean> {
    const url = URL.createObjectURL(res.file);
    const data = { name: res.file.name, url: url, uid: res.file.uid };
    setTimeout(() => {
      this.addMesh(data);
      this.handleSelect(data);
    }, 500);
    return true;
  }

  async uploadImageFile(res: any): Promise<boolean> {
    fileContentToBase64(res.file, (encodedString) => {
      this.selectedInput.thumbnail = res.file.name;
      this.selectedInput.thumbnailFiletype = getImageFileType(res.file.name),
      this.selectedInput.thumbnailUrl = encodedString;
    });
    return true;
  }

  handleRemove(file: UploadFile): void {
    ElMessageBox.confirm(
      (this as any).$t('components.mesh-loader.deleteMessage'),
      'info',
      {
        confirmButtonText: (this as any).$t('components.mesh-loader.yes'),
        cancelButtonText: (this as any).$t('components.mesh-loader.no'),
        type: 'warning',
      }
    )
      .then((confirm) => {
        if (confirm) {
          const item = this.selectedInput.treeData.find(x => x.name === file.uid.toString())
          if (item) {
            this.selectedInput.removeItem(item);
          }
          this.handleSelect(null);
        }
      })
      .catch(() => {
        //
      });
  }

  handleRemoveImage(file: UploadFile): void {
    ElMessageBox.confirm(
      (this as any).$t('components.mesh-loader.deleteMessage'),
      'info',
      {
        confirmButtonText: (this as any).$t('components.mesh-loader.yes'),
        cancelButtonText: (this as any).$t('components.mesh-loader.no'),
        type: 'warning',
      }
    )
      .then((confirm) => {
        if (confirm) {
          this.selectedInput.thumbnail = null;
          this.selectedInput.thumbnailUrl = null;
          this.selectedInput.thumbnailFiletype = null;
        }
      })
      .catch(() => {
        //
      });
  }

  addMesh(file: UploadData | null): void {
    if (file) {
      const fileType = getFileType(file.name) as FileType3D;
      if (fileType !== FileType3D.NONE) {
        this.selected = file.uid;
        if (file.url) {
          const item = this.selectedInput.addImport(
            file.uid.toString(),
            file.name,
            file.url,
            '',
            '',
            null,
            true
          );
          item.bones = this.boneData;
          item.activeBoneIndex = this.activeBoneIndex;
          this.loadingData.url = file.url;
          this.loadingData.fileType = fileType;
        }
      }
    }
  }

  handleSelect(file: UploadData | null): void {
    if (file) {
      const fileType = getFileType(file.name) as FileType3D;
      if (fileType !== FileType3D.NONE) {
        this.selected = file.uid;
        for(const treeData of this.selectedInput.treeData) {
          if (treeData.name == this.selected.toString()) {
            treeData.isSelected = true;
          }
        }
      }
    } else {
      this.selected = -1;
    }
  }

  beforeUpload(file: any): boolean {
    if (isValidFileType(file.name)) {
      return true;
    }
    ElMessage.error((this as any).$t('components.mesh-loader.wrongType'));
    return false;
  }

  beforeImageUpload(file: any): boolean {
    if (isValidImageFileType(file.name)) {
      if(this.selectedInput.thumbnailUrl == null) {
        return true;
      }
      ElMessage.error((this as any).$t('components.mesh-loader.onlyOneImage'));
      return false;
    }
    ElMessage.error((this as any).$t('components.mesh-loader.wrongType'));
    return false;
  }

  async defaultUndo(): Promise<void> {
    //
  }

  async defaultRedo(): Promise<void> {
    //
  }
}

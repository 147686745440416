
import { Options, Vue } from 'vue-class-component';
import { MeshTreeData, MeshTreeDataItem } from '@/types/ui/MeshTreeData';
import { RendererPublicInterface } from 'troisjs';
import { SelectionList } from '@/types/ui/SelectionList';
import { ViewCtrl } from '@/types/ui/ViewCtrl';
import { EditorMode } from '@/types/ui/EditorMode';
import { Prop, Watch } from 'vue-property-decorator';
import * as THREE from 'three';
import TransformTools from '@/components/Tools/TransformTools.vue';
import * as THREEEnum from '@/types/enum/three';
import { SpecialObjectName } from '@/types/enum/three';
import * as THREEBoundingBox from '@/utils/three/boundingBox';
import {
  EmbossingData,
  ProjectionSidebarData,
} from '@/components/Tools/EmbossingSidebar.vue';
import * as THREEMaterial from '@/utils/three/material';
import { ProjectionCategory } from '@/types/enum/editor';
import {
  HistoryInfo,
  HistoryList,
  HistoryOperationType,
} from '@/types/ui/HistoryList';
import { EmbossingExportData } from '@/services/api/templateService';

interface ProjectionData extends ProjectionSidebarData {
  projectionType: THREEEnum.ProjectionType;
  position: THREE.Vector3 | null;
  rotation: THREE.Euler;
  scale: THREE.Vector3;
}

@Options({
  components: { TransformTools },
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class ProjectionTools extends Vue implements HistoryInfo {
  //#region properties
  @Prop() modelValue!: MeshTreeData;
  @Prop() troisRenderer!: RendererPublicInterface;
  @Prop() editorMode!: EditorMode;
  @Prop() selectionList!: SelectionList;
  @Prop() viewCtrl!: ViewCtrl;
  @Prop({ default: true }) canCurve!: boolean;
  @Prop() readonly selectObject!: (
    target: THREE.Object3D | null,
    setSelectionFlag: boolean
  ) => Promise<void>;
  @Prop({ default: true }) canProject!: boolean;
  @Prop({
    default: {
      scale: 1,
      embossingFile: null,
      color: '#ffffff',
    },
  })
  embossing!: EmbossingData;
  @Prop() historyList!: HistoryList;

  //history
  lastUpdateTime = -1;

  // transform
  transformTools: TransformTools | null = null;
  projectionReference!: THREE.Mesh;

  //projection
  activeProjectionType: THREEEnum.ProjectionType =
    THREEEnum.ProjectionType.cylinder;
  isProjectionTypeCylinder = true;
  isProjectionTypePlane = false;

  //data
  projectionDataPerMesh: {
    [name: string]: EmbossingData;
  } = {};

  // enums for use in template section
  ProjectionType = THREEEnum.ProjectionType;
  //#endregion properties

  //#region load / unload
  @Watch('lastUpdateTime', { immediate: true })
  onDataChanged(): void {
    if (this.lastUpdateTime > -1) {
      const mesh = this.selectionList.getSelectedObjectSaveId();
      if (mesh) this.modelValue.updateDB(mesh);
    }
  }
  //#endregion load / unload

  //#region projection
  @Watch('embossing', { immediate: true, deep: true })
  onEmbossingChanged(restoreAndSave = true): void {
    //todo: fix performance - too many calls (deep: true)
    if (this.projectionReference) {
      const selection = this.selectionList.getSelectedObject();
      const embossing = this.getActiveEmbossing(this.embossing);
      if (embossing) {
        if (selection && embossing.color)
          THREEMaterial.setColor(selection, embossing.color);
        THREEMaterial.setTextureFile(
          this.projectionReference,
          embossing.embossingFile
        );
        this.updateProjectionProportions();
      }
      if (restoreAndSave) {
        this.saveData(false);
        if (this.canProject) this.createProjectionReferenceForSelectedObject();
      }
    }
  }

  @Watch('canProject', { immediate: true })
  onCanProjectionChanged(): void {
    if (this.canProject) this.createProjectionReferenceForSelectedObject();
    if (this.projectionReference)
      this.projectionReference.visible = this.canProject;
  }

  changeTransform(): void {
    this.updateProjectionProportions();
    this.saveData();
  }

  updateProjectionProportions(): void {
    const embossing = this.getActiveEmbossing(this.embossing);
    if (embossing && this.projectionReference) {
      THREEMaterial.scaleTexture(
        this.projectionReference,
        embossing.scaleFactor,
        embossing.scaleFactor,
        embossing.offset.x,
        embossing.offset.y,
        this.getProjectionProportions()
      );
    }
  }

  getProjectionProportions(): number {
    if (this.projectionReference) {
      const box = THREEBoundingBox.getBoundingBox(
        [this.projectionReference],
        true,
        false
      );
      const x = (box.max.x - box.min.x) * this.projectionReference.scale.x;
      const y = (box.max.y - box.min.y) * this.projectionReference.scale.y;
      //const z = (box.max.z - box.min.z) * this.projectionReference.scale.z;
      if (this.isProjectionTypePlane) {
        return x / y;
      } else {
        return (x * Math.PI) / y;
      }
    }
    return 1;
  }

  setProjectionType(
    type: THREEEnum.ProjectionType,
    restoreAndSave = true
  ): void {
    if (this.activeProjectionType !== type) {
      this.activeProjectionType = type;
      this.isProjectionTypeCylinder =
        type === THREEEnum.ProjectionType.cylinder;
      this.isProjectionTypePlane = type === THREEEnum.ProjectionType.plane;
      if (restoreAndSave) {
        this.saveData();
        if (this.canProject) {
          this.createProjectionReferenceForSelectedObject();
          const meshId = this.selectionList.getSelectedObjectUuid();
          if (meshId) {
            const oldType = this.isProjectionTypeCylinder
              ? THREEEnum.ProjectionType.plane
              : THREEEnum.ProjectionType.cylinder;
            const mesh = this.selectionList.getSelectedObject();
            if (mesh) {
              this.historyList.add(
                meshId,
                HistoryOperationType.embossing,
                async () => {
                  this.undoRedoProjectionType(mesh, oldType);
                },
                async () => {
                  this.undoRedoProjectionType(mesh, type);
                }
              );
            }
          }
        }
      }
    }
  }

  undoRedoProjectionType(
    object: THREE.Object3D,
    type: THREEEnum.ProjectionType
  ): void {
    this.selectObject(object, true).then(() => {
      if (this.activeProjectionType !== type) {
        this.activeProjectionType = type;
        this.isProjectionTypeCylinder =
          type === THREEEnum.ProjectionType.cylinder;
        this.isProjectionTypePlane = type === THREEEnum.ProjectionType.plane;
        this.saveData();
        if (this.canProject) {
          this.createProjectionReferenceForObject([object]);
        }
      }
    });
  }

  private getActiveProjection(): ProjectionData | null {
    const selection = this.selectionList.getSelectedObjectSaveId();
    if (selection) {
      const data = this.projectionDataPerMesh[selection];
      return this.getActiveEmbossing(data);
    }
    return null;
  }

  getActiveEmbossing(data: EmbossingData): ProjectionData | null {
    if (data) {
      const activeCategory = data.activeCategory;
      const index = data.projection.findIndex(
        (item) => item.projectionCategory === activeCategory
      );
      if (index > -1) {
        return data.projection[index] as ProjectionData;
      }
    }
    return null;
  }
  //#endregion projection

  //#region create / save
  private saveData(saveAll = true): void {
    const selection = this.selectionList.getSelectedObjectSaveId();
    const embossing = this.getActiveEmbossing(this.embossing);
    if (selection && this.projectionReference && embossing) {
      const projection: ProjectionData = {
        projectionType: this.activeProjectionType,
        position: this.projectionReference.position.clone(),
        rotation: this.projectionReference.rotation.clone(),
        scale: this.projectionReference.scale.clone(),
        projectionCategory: embossing.projectionCategory,
        aspectRatio: this.getProjectionProportions(),
        scaleFactor: embossing.scaleFactor,
        offset: { x: embossing.offset.x, y: embossing.offset.y },
        embossingDefinition: embossing.embossingDefinition,
        colorDefinition: embossing.colorDefinition,
        embossingFile: embossing.embossingFile,
        color: embossing.color,
      };
      this.saveItem(selection, projection, saveAll);
      this.lastUpdateTime = Date.now();
    }
  }

  private saveItem(
    meshId: string,
    projection: ProjectionData,
    saveAll = true
  ): void {
    if (!this.projectionDataPerMesh[meshId]) {
      this.projectionDataPerMesh[meshId] = {
        id: meshId,
        activeCategory: projection.projectionCategory,
        projection: [projection],
      };
    } else {
      const dbItem = this.projectionDataPerMesh[meshId];
      dbItem.activeCategory = projection.projectionCategory;
      const activeCategory = dbItem.activeCategory;
      const index = dbItem.projection.findIndex(
        (item) => item.projectionCategory === activeCategory
      );
      if (index > -1) {
        if (saveAll) dbItem.projection[index] = projection;
        else {
          dbItem.projection[index].projectionCategory =
            projection.projectionCategory;
          dbItem.projection[index].scaleFactor = projection.scaleFactor;
          dbItem.projection[index].offset.x = projection.offset.x;
          dbItem.projection[index].offset.y = projection.offset.y;
          dbItem.projection[index].embossingDefinition =
            projection.embossingDefinition;
          dbItem.projection[index].colorDefinition = projection.colorDefinition;
          dbItem.projection[index].embossingFile = projection.embossingFile;
          dbItem.projection[index].color = projection.color;
        }
      } else {
        dbItem.projection.push(projection);
      }
    }
  }

  createProjectionReferenceForSelectedObject(restoreAndSave = true): void {
    const selection = this.selectionList.getSelectedObjects();
    this.createProjectionReferenceForObject(selection, restoreAndSave);
  }

  createProjectionReferenceForObject(
    objects: THREE.Object3D[],
    restoreAndSave = true
  ): void {
    if (this.canProject) {
      if (this.projectionReference) {
        this.projectionReference.visible = false;
        if (this.transformTools)
          this.transformTools.setTransformControlsForTarget(null);
      }
      if (objects.length > 0) {
        const apiData = (objects[0] as any).apiData as MeshTreeDataItem;
        if (!apiData || apiData.isSpecialObject()) return;
        const objectId = (objects[0] as any).apiData.id;
        if (restoreAndSave) {
          this.restoreData();
        }
        const boundingBox = THREEBoundingBox.getBoundingBox(objects);
        const boxSize = boundingBox.getSize(new THREE.Vector3());
        const boxCenter = boundingBox.getCenter(new THREE.Vector3());
        const height = boundingBox.max.y - boundingBox.min.y;
        const path = new THREE.LineCurve3(
          new THREE.Vector3(0, -height / 2, 0),
          new THREE.Vector3(0, height / 2, 0)
        );
        const geometry = this.isProjectionTypeCylinder
          ? new THREE.TubeGeometry(
              path,
              1,
              Math.max(boxSize.x, boxSize.z) / 2,
              20,
              false
            )
          : new THREE.PlaneGeometry(boxSize.x, boxSize.y);

        const uvs = geometry.attributes.uv as THREE.Float32BufferAttribute;
        if (this.isProjectionTypeCylinder) {
          const uvCoordsOriginal = [...(uvs.array as number[])];
          const uvCoordsNew = [...uvCoordsOriginal];
          for (let i = 0; i < uvs.count; i++) {
            uvCoordsNew[i * 2] = uvCoordsOriginal[i * 2 + 1];
            uvCoordsNew[i * 2 + 1] = uvCoordsOriginal[i * 2];
          }
          uvs.set(uvCoordsNew);
          uvs.needsUpdate = true;
        }
        if (!this.projectionReference) {
          const material = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            side: THREE.DoubleSide,
            opacity: 50 / 100.0,
            transparent: true,
          });
          this.projectionReference = new THREE.Mesh(geometry, material);
          this.projectionReference.name = SpecialObjectName.ProjectionPlane;
          this.onEmbossingChanged(false);
        } else {
          this.projectionReference.geometry = geometry;
        }
        this.projectionReference.uuid = objectId;
        this.projectionReference.visible = true;

        if (this.transformTools) {
          this.transformTools.setTransformControlsForTarget(
            this.projectionReference,
            false
          );
        }
        objects[0].add(this.projectionReference);
        this.projectionReference.position.copy(boxCenter.clone());
        this.projectionReference.rotation.set(0, 0, 0);
        this.projectionReference.scale.set(1, 1, 1);
        if (restoreAndSave) {
          if (this.projectionDataPerMesh[objectId]) {
            this.restoreData();
            const index = this.embossing.projection.findIndex(
              (item) =>
                item.projectionCategory === this.embossing.activeCategory
            );
            if (index > -1) {
              this.embossing.projection[index].aspectRatio =
                this.getProjectionProportions();
            }
          } else {
            this.saveData();
          }
        }
        this.updateProjectionProportions();
      }
    }
  }

  private createEmptyData(): void {
    const selectionId = this.selectionList.getSelectedObjectSaveId();
    if (selectionId) {
      const selection = this.selectionList.getSelectedObject();
      const data = this.projectionDataPerMesh[selectionId];
      const embossing = this.getActiveEmbossing(this.embossing);
      if (!data && embossing) {
        const projection: ProjectionData = {
          projectionType: this.activeProjectionType,
          position: null,
          rotation: new THREE.Euler(0, 0, 0),
          scale: new THREE.Vector3(1, 1, 1),
          projectionCategory: ProjectionCategory.custom,
          aspectRatio: 1,
          scaleFactor: 1,
          offset: { x: 0, y: 0 },
          embossingDefinition: [],
          colorDefinition: {},
          embossingFile: null,
          color: selection ? THREEMaterial.getColor(selection) : null,
        };
        this.projectionDataPerMesh[selectionId] = {
          id: selectionId,
          activeCategory: ProjectionCategory.custom,
          projection: [projection],
        };
        this.embossing.projection = [projection];
        this.embossing.activeCategory = ProjectionCategory.custom;
      }
    }
  }

  private restoreData(): void {
    this.createEmptyData();
    const selection = this.selectionList.getSelectedObjectSaveId();
    if (selection) {
      const meshData = this.projectionDataPerMesh[selection];
      if (meshData) {
        let hasChanges =
          this.embossing.projection.length !== meshData.projection.length;
        if (!hasChanges) {
          for (let i = 0; i < this.embossing.projection.length; i++) {
            hasChanges =
              this.embossing.projection[i].projectionCategory !==
              meshData.projection[i].projectionCategory;
            if (hasChanges) break;
          }
        }
        if (hasChanges) this.embossing.projection = [...meshData.projection];
        if (this.embossing.activeCategory !== meshData.activeCategory)
          this.embossing.activeCategory = meshData.activeCategory;
      }
      const data = this.getActiveProjection();
      if (data) {
        this.setProjectionType(data.projectionType, false);
        if (this.projectionReference) {
          if (data.position)
            this.projectionReference.position.copy(data.position.clone());
          this.projectionReference.rotation.copy(data.rotation.clone());
          this.projectionReference.scale.copy(data.scale.clone());
        }
        if (this.embossing) {
          if (this.embossing.id !== meshData.id)
            this.embossing.id = meshData.id;
          const embossing = this.getActiveEmbossing(this.embossing);
          if (embossing) {
            if (embossing.scaleFactor !== data.scaleFactor)
              embossing.scaleFactor = data.scaleFactor;
            if (embossing.offset.x !== data.offset.x)
              embossing.offset.x = data.offset.x;
            if (embossing.offset.y !== data.offset.y)
              embossing.offset.y = data.offset.y;
            if (embossing.embossingDefinition !== data.embossingDefinition)
              embossing.embossingDefinition = data.embossingDefinition;
            if (embossing.colorDefinition !== data.colorDefinition)
              embossing.colorDefinition = data.colorDefinition;
            if (embossing.embossingFile !== data.embossingFile)
              embossing.embossingFile = data.embossingFile;
            if (embossing.color !== data.color) embossing.color = data.color;
          }
        }
      }
    }
  }
  //#endregion create / save

  //#region import / export
  exportEmbossingForMeshId(meshId: string): EmbossingExportData[] {
    const data = this.projectionDataPerMesh[meshId];
    if (data) {
      return data.projection.map((item) => {
        const projection = item as ProjectionData;
        return {
          projectionType: projection.projectionType,
          position: projection.position,
          rotation: projection.rotation,
          scale: projection.scale,
          projection: item,
        };
      });
    }
    return [];
  }

  importEmbossingForMeshId(
    meshId: string,
    embossingList: EmbossingExportData[]
  ): void {
    for (const item of embossingList) {
      const projection: ProjectionData = {
        projectionType: item.projectionType,
        position: item.position ? item.position.clone() : null,
        rotation: item.rotation.clone(),
        scale: item.scale.clone(),
        projectionCategory: item.projection.projectionCategory,
        aspectRatio: item.projection.aspectRatio,
        scaleFactor: item.projection.scaleFactor,
        offset: { x: item.projection.offset.x, y: item.projection.offset.y },
        embossingDefinition: item.projection.embossingDefinition,
        colorDefinition: item.projection.colorDefinition,
        embossingFile: item.projection.embossingFile,
        color: item.projection.color,
      };
      this.saveItem(meshId, projection);
    }
  }
  //#endregion import / export
}

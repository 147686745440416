
import { Options, Vue } from 'vue-class-component';
import MeshLoader from '@/components/three/MeshLoader.vue';
import { UploadProgress } from '@/types/enum/upload';
import * as LayoutUtility from '@/utils/layout';
import * as OrderService from '@/services/api/orderWorkflowService';
import { Prop, Watch } from 'vue-property-decorator';
import Workflow from '@/components/workflow/Workflow.vue';
import { WorkflowModel } from '@/types/ui/WorkflowModel';

@Options({
  components: {
    MeshLoader,
    Workflow,
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class OrderUpload extends Vue {
  @Prop() templateId!: number;
  @Prop() orderId!: number;

  workflowModel: WorkflowModel = new WorkflowModel(
    'upload-progress',
    UploadProgress
  );
  fullscreenLoading = false;

  get activeUploadProgress(): UploadProgress {
    if (this.workflowModel.active < this.workflowModel.stepCount) {
      LayoutUtility.refresh();
      return Object.values(UploadProgress)[this.workflowModel.active];
    }
    return UploadProgress.Pivot;
  }

  set activeUploadProgress(value: UploadProgress) {
    this.workflowModel.active = Object.values(UploadProgress).indexOf(value);
  }

  @Watch('workflowModel.finished', { immediate: true })
  onStepFinished(): void {
    if (this.workflowModel.finished) {
      this.fullscreenLoading = true;
      setTimeout(() => {
        const loader = this.$refs.meshLoader as MeshLoader;
        if (loader) {
          const result = loader.getResult();
          if (result)
            OrderService.addMeshData(
              this.orderId,
              this.templateId,
              result.category,
              result.title,
              result.mesh,
              result.bones,
              null
            ).then(() => {
              this.$router.go(-1);
            });
        }
      }, 100);
    }
  }
}


import { BodySide } from "@/types/enum/order";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
})

export default class ProductParamterCuboid extends Vue {
  @Prop() properties!: any[];
  @Prop({default: false}) readonly!: boolean;
}

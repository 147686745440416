
import { Options, Vue } from 'vue-class-component';
import * as modelService from '@/services/api/modelService';
import { Model, Model2 } from '@/types/api/Model/Model';
import { FileTypeImage } from '@/types/enum/upload';
import PreviewRenderer from '@/components/three/PreviewRenderer.vue';
import PreviewRendererImg from '@/components/three/PreviewRendererImg.vue';
import SideMenu from '@/components/workflow/SideMenu.vue';
import ToggleSidebar from '@/components/element-plus/ToggleSidebar.vue';

@Options({
  components: {
    SideMenu,
    ToggleSidebar,
    PreviewRenderer,
    PreviewRendererImg
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class OrderOverview extends Vue {
  modelList: Model2[] = [];
  FileType3D = FileTypeImage;
  showMenu = true;
  modelsLoading = false;

  mounted(): void {
    this.modelsLoading = true;
    modelService.getModelOverviewList().then(async (result) => {
      if (result) {
        const modelList = result.filter((item) => !item.basedOn);
        this.modelList = modelList;
      } else this.modelList = [];
      this.modelsLoading = false;
    });
  }

  convertToUrl(model: Model): string | null {
    if (
      model.product &&
      model.product.thumbnail
    ) {
      return model.product.thumbnail.content;
    }
    return null;
  }

  displayModel(model: Model, event: PointerEvent): void {
    const path = event.composedPath();
    if (!path.find((item) => (item as any).id === 'deleteModel'))
      this.$router.push(`/order-workflow?templateId=${model.modelId}`);
  }
}

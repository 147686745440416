
import { BodySide, ZipType } from "@/types/enum/order";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import * as CustomerService from "@/services/api/customerService";
import Auth from "@/services/api/auth";
import { Customer } from "@/types/api/User/Customer";
import { Body_Attribute } from "@/types/api/Patient/BodyAttribute";
import { File } from "@/types/api/Utility/File";
import { Product } from "@/types/api/Model/Product";

@Options({
  components: {},
  emits: ['scanFileChanged', 'zipChanged'],
})

export default class ProductParamterCover extends Vue {
  @Prop() properties!: any[];
  @Prop() product!: Product;
  @Prop({ default: false }) readonly!: boolean; 

  authenticator = Auth.getInstance();
  bodyAttributes: Body_Attribute[] = [];

  BodySide = BodySide;
  ZipType = ZipType;
  selectedBodyAttribute: Body_Attribute | null = null;

  automaticFit = false;


  mounted(): void {
    this.properties[0].heightWithoutDeduction = 420;
    this.properties[0].depth = 380;
    this.properties[0].calfCircumferenceWithoutDeduction = 240;

    if (this.authenticator.user) {
      this.automaticFit = false;
      CustomerService.getBodyAttributesByUserId(this.authenticator.user.userId)
        .then((bodyAttributes) => {
          this.bodyAttributes = bodyAttributes;
        })
    }
  }

  automaticFitChanged(): void {
    if (!this.automaticFit) this.selectedBodyAttribute = null; 
  }
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2446d793"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "orientationProductQuality d-flex" }
const _hoisted_2 = { class: "productQualityTitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('views.productQuality.title')), 1),
    _createVNode(_component_el_select, {
      modelValue: _ctx.currentProductQualityIndex,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentProductQualityIndex) = $event)),
      onChange: _ctx.onProductQualityChanged
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_ctx.ProductQualityType), (qualilty) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: qualilty,
            value: Object.values(_ctx.ProductQualityType).indexOf(qualilty),
            label: _ctx.$t(`enum.productQuality.${qualilty}`)
          }, null, 8, ["value", "label"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "onChange"])
  ]))
}

import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { WorkflowModel } from '@/types/ui/WorkflowModel';

export default class Workflow extends Vue {
  @Prop({ default: null }) workflowModel!: WorkflowModel;
  @Prop({ default: false }) fullscreenLoading!: boolean;
  @Prop({ default: 0 }) isSmallWindowSize!: boolean;
}


import { Options, Vue } from 'vue-class-component';
import Auth from '@/services/api/auth';
import { Role } from '@/types/enum/user';
import { User } from '@/types/api/User/User';
import CustomHeader from '@/components/CustomHeader.vue';
import CustomFooter from '@/components/CustomFooter.vue';
import * as LayoutUtility from '@/utils/layout'

@Options({
  components: {
    CustomHeader,
    CustomFooter
  },
})
export default class Home extends Vue {
  authentication = Auth.getInstance();
  menuLoading = false;
  Role = Role;

  created(): void {
    this.menuLoading = true;
    this.authentication.handleAuthentication().then(() => {
      if (this.authentication.isAuthenticated()) {
        return;
      }
      this.$router.push("/aidddo");
    }).finally(() => {
      this.menuLoading = false;
    });
    //this.$router.push("/aidddo");
  }
}

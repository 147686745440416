
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ObjModel, StlModel } from '@/utils/three/importExport';
import { MeshImportData, MeshTreeDataItem } from '@/types/ui/MeshTreeData';
import { FileType3D } from '@/types/enum/upload';
import { v4 as uuidv4 } from 'uuid';
import { deleteWebGlContext } from '@/utils/three/webGlContext';

@Options({
  components: {
    ObjModel,
    StlModel,
  },
  emits: ['selectItem', 'removeItem', 'takeSnapshot'],
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class PreviewRendererImg extends Vue {
  @Prop() meshes!: MeshTreeDataItem[];
  @Prop({ default: false }) isSelected!: boolean;
  @Prop({ default: '10' }) imgSize!: string;
  @Prop({ default: 1.2 }) offset!: number;
  @Prop({ default: true }) canSelect!: boolean;
  @Prop({ default: false }) canRemove!: boolean;
  @Prop({ default: false }) canTakeSnapshot!: boolean;
  uuid = uuidv4();

  FileType = FileType3D;

  unmounted(): void {
    deleteWebGlContext(this.uuid);
  }

  selectItem(mesh: MeshTreeDataItem): void {
    if (this.canSelect) this.$emit('selectItem', mesh);
  }

  get isEmpty(): boolean {
    return (
      this.meshes.filter((item) => item.thumbnail && item.thumbnailUrl)
        .length === 0
    );
  }

  getMeshesOfImageType(): MeshImportData[] {
    return this.meshes.filter((item) => item.thumbnail && item.thumbnailUrl);
  }

  takeSnapshot(mesh: MeshTreeDataItem): void {
    if (mesh.isSelected) {
      this.$emit('takeSnapshot');
    } else {
      this.selectItem(mesh);
    }
  }

  /*isEmpty(): boolean {
    return this.url === '';
  }*/
}

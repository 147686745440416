import * as THREE from 'three';
import { FileType3D, UploadCategory } from '@/types/enum/upload';
import { v4 as uuidv4 } from 'uuid';
import { convertMesh } from '@/services/api/templateService';
import * as modelService from '@/services/api/modelService';
import * as componentService from '@/services/api/componentService';

/* eslint-disable @typescript-eslint/no-explicit-any*/

export interface BoneData {
  name: string;
  position: THREE.Vector3;
}

export interface OrderData {
  templateId: number | null;
  orderId: number;
  meshes: modelService.MeshExportData[];
  curves: modelService.CurveExportData[];
  cameras: modelService.CameraExportData[];
}

const localOrderDB: { [key: number]: OrderData } = {};

export const getData = async (orderId: number): Promise<OrderData> => {
  if (!(orderId in localOrderDB)) {
    localOrderDB[orderId] = {
      templateId: null,
      orderId: orderId,
      meshes: [],
      curves: [],
      cameras: [],
    };
  }
  const result = await modelService.getModel(orderId);
  if (result.basedOn) localOrderDB[orderId].templateId = result.basedOn.modelId;
  for (const component of result.components) {
    const meshIndex = localOrderDB[orderId].meshes.findIndex(
      (item) => item.componentId === component.componentId
    );
    if (meshIndex === -1) {
      localOrderDB[orderId].meshes.push(
        modelService.parseToMeshExportData(component, component.resolutions[0])
      );
    } else {
      localOrderDB[orderId].meshes[meshIndex] =
        modelService.parseToMeshExportData(component, component.resolutions[0]);
    }
    for (const curve of component.curves) {
      const curveIndex = localOrderDB[orderId].curves.findIndex(
        (item) => item.curveId === curve.curveId
      );
      if (curveIndex === -1) {
        localOrderDB[orderId].curves.push(
          modelService.parseToCurveExportData(curve, component)
        );
      } else {
        localOrderDB[orderId].curves[curveIndex] =
          modelService.parseToCurveExportData(curve, component);
      }
    }
  }
  for (const curve of result.curves) {
    const curveIndex = localOrderDB[orderId].curves.findIndex(
      (item) => item.curveId === curve.curveId
    );
    if (curveIndex === -1) {
      localOrderDB[orderId].curves.push(
        modelService.parseToCurveExportData(curve)
      );
    } else {
      localOrderDB[orderId].curves[curveIndex] =
        modelService.parseToCurveExportData(curve);
    }
  }
  for (const camera of result.cameras) {
    const cameraIndex = localOrderDB[orderId].cameras.findIndex(
      (item) => item.cameraId === camera.cameraId
    );
    if (cameraIndex === -1) {
      localOrderDB[orderId].cameras.push(
        modelService.parseToCameraExportData(camera)
      );
    } else {
      localOrderDB[orderId].cameras[cameraIndex] =
        modelService.parseToCameraExportData(camera);
    }
  }
  return localOrderDB[orderId];
};

const createFromTemplate = async (templateId: number): Promise<number> => {
  return modelService.createFromTemplate(templateId);
};

export const checkDataForId = async (
  orderId: number | null,
  templateId: number
): Promise<OrderData> => {
  if (!orderId) {
    const newId = await createFromTemplate(templateId);
    const data = await getData(newId);
    localOrderDB[newId].templateId = templateId;
    return data;
  } else {
    return await getData(orderId);
  }
};

export const getMeshData = async (
  orderId: number
): Promise<modelService.MeshExportData[]> => {
  const data = await getData(orderId);
  return data.meshes;
};

export const getTemplateId = async (
  orderId: number
): Promise<number | null> => {
  const data = await getData(orderId);
  return data.templateId;
};

export const getCurveData = async (
  orderId: number
): Promise<modelService.CurveExportData[]> => {
  const data = await getData(orderId);
  return data.curves;
};

export const getCameraData = async (
  orderId: number
): Promise<modelService.CameraExportData[]> => {
  const data = await getData(orderId);
  return data.cameras;
};

export const addMeshData = async (
  orderId: number,
  templateId: number,
  meshCategory: UploadCategory,
  meshName: string,
  mesh: THREE.Object3D,
  bones: THREE.Bone[],
  parentId: number | null = null,
  uploadToDB = true
): Promise<string> => {
  const id = (mesh as any).apiData ? (mesh as any).apiData.id : uuidv4();
  convertMesh(mesh).then(async ({ fileType, base64 }) => {
    if (!base64) {
      fileType = FileType3D.NONE;
      base64 = '';
    }
    if (uploadToDB) {
      await componentService.addComponents(
        orderId,
        id,
        meshName,
        base64,
        meshName,
        fileType.toString(),
        mesh.position,
        mesh.rotation,
        mesh.scale,
        meshCategory,
        1,
        null,
        '.jpg',
        bones,
        parentId
      );
    }

    getMeshData(orderId);
  });
  return id;
};

export const updateMeshData = async (
  orderId: number,
  templateId: number,
  id: string,
  meshCategory: UploadCategory,
  meshName: string,
  mesh: THREE.Object3D,
  bones: THREE.Bone[],
  parentId: number | null = null,
  uploadToDB = true
): Promise<string> => {
  const data = await getMeshData(orderId);
  const dbIndex = data.findIndex((item) => item.uniqueKey === id);
  if (dbIndex > -1) {
    if (
      (mesh as any).isGroup &&
      mesh.children.length > 0 &&
      (mesh.children[0] as any).isMesh
    )
      mesh = mesh.children[0];

    let { fileType, base64 } = await convertMesh(mesh);
    if (!base64) {
      fileType = FileType3D.NONE;
      base64 = '';
    }
    if (uploadToDB) {
      componentService.updateComponent(
        orderId,
        id,
        meshName,
        base64,
        meshName,
        fileType.toString(),
        mesh.position,
        mesh.rotation,
        mesh.scale,
        meshCategory,
        1,
        null,
        '.jpg',
        bones,
        parentId
      );
    }
    if (orderId) {
      getMeshData(orderId);
    }
    return id;
  } else {
    return await addMeshData(
      orderId,
      templateId,
      meshCategory,
      meshName,
      mesh,
      bones,
      parentId,
      uploadToDB
    );
  }
};

export const deleteMeshData = async (
  orderId: number,
  id: string
): Promise<void> => {
  const data = await getMeshData(orderId);
  const dbIndex = data.findIndex((item) => item.uniqueKey === id);
  if (dbIndex > -1) {
    data.splice(dbIndex, 1);
  }
};

const getMeshDataItem = async (
  orderId: number,
  id: string
): Promise<modelService.MeshExportData | null> => {
  const data = await getMeshData(orderId);
  const dbIndex = data.findIndex((item) => item.uniqueKey === id);
  if (dbIndex > -1) {
    return data[dbIndex];
  }
  return null;
};

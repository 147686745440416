import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b205aca"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "product-parameter",
  class: "pl-5 pr-5 pt-3 pb-3 rounded-3 text-white"
}
const _hoisted_2 = { class: "row ml-0 mr-0" }
const _hoisted_3 = { class: "col col-lg-8" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = {
  class: "row form-text text-white",
  id: "product-details-info-text"
}
const _hoisted_6 = { class: "col col-lg-4 d-flex justify-content-end" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "row d-flex justify-content-center mb-5 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_parameter_cover = _resolveComponent("product-parameter-cover")!
  const _component_product_parameter_orthesis = _resolveComponent("product-parameter-orthesis")!
  const _component_product_parameter_balcony = _resolveComponent("product-parameter-balcony")!
  const _component_product_parameter_fence = _resolveComponent("product-parameter-fence")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t('components.product-parameter.title')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.product.name), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.product.description), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.product.type.name.toLowerCase() == 'covers')
        ? (_openBlock(), _createBlock(_component_product_parameter_cover, {
            key: 0,
            properties: _ctx.properties,
            product: _ctx.product,
            onZipChanged: _cache[0] || (_cache[0] = (zip) => _ctx.$emit('zipChanged', zip)),
            onScanFileChanged: _ctx.selected,
            readonly: _ctx.readonly
          }, null, 8, ["properties", "product", "onScanFileChanged", "readonly"]))
        : _createCommentVNode("", true),
      (_ctx.product.type.name.toLowerCase() == 'orthesen')
        ? (_openBlock(), _createBlock(_component_product_parameter_orthesis, {
            key: 1,
            properties: _ctx.properties,
            product: _ctx.product,
            onZipChanged: _cache[1] || (_cache[1] = (zip) => _ctx.$emit('zipChanged', zip)),
            onScanFileChanged: _ctx.selected,
            readonly: _ctx.readonly
          }, null, 8, ["properties", "product", "onScanFileChanged", "readonly"]))
        : _createCommentVNode("", true),
      (_ctx.product.type.name.toLowerCase() == 'balkone')
        ? (_openBlock(), _createBlock(_component_product_parameter_balcony, {
            key: 2,
            properties: _ctx.properties,
            readonly: _ctx.readonly
          }, null, 8, ["properties", "readonly"]))
        : _createCommentVNode("", true),
      (_ctx.product.type.name.toLowerCase() == 'zäune')
        ? (_openBlock(), _createBlock(_component_product_parameter_fence, {
            key: 3,
            properties: _ctx.properties,
            readonly: _ctx.readonly
          }, null, 8, ["properties", "readonly"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-primary",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('buttonClicked', _ctx.selectedBodyAttribute)))
        }, _toDisplayString(_ctx.$t('components.product-parameter.startConfigurator')), 1)
      ])
    ])
  ]))
}
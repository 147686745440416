
import { OrderRequestData } from '@/services/api/orderService';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ValidationForm from './element-plus/ValidationForm.vue';
import { BodySide, ZipType } from '@/types/enum/order';
import { WorkflowModel } from '@/types/ui/WorkflowModel';
import { Product } from '@/types/api/Model/Product';
import ProductParameterCover from "@/components/productParameters/ProductParameterCover.vue";
import ProductParameterCuboid from "@/components/productParameters/ProductParameterCuboid.vue";
import ProductParameterBalcony from "@/components/productParameters/ProductParameterBalcony.vue";
import ProductParameterFence from "@/components/productParameters/ProductParameterFence.vue";
import ProductParameterOrthesis from "@/components/productParameters/ProductParameterOrthesis.vue";

@Options({
  components: {
    ValidationForm,
    ProductParameterCover,
    ProductParameterCuboid,
    ProductParameterBalcony,
    ProductParameterFence,
    ProductParameterOrthesis
  },
  emits: ['zipChanged', 'sizeChanged'],
})

export default class OrderForm extends Vue {
  @Prop() order!: OrderRequestData;
  @Prop() workflowModel!: WorkflowModel;
  @Prop() product!: Product;

  BodySide = BodySide;
  ZipType = ZipType;

  privacyChanged(): void {
    if (this.order.isPrivacyAccepted) {
      this.order.privacy = "yes";
    } else {
      this.order.privacy = "";
    }
  }
}

